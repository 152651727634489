import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from './Modal';
import { subscribe } from '../../../redux/actions/authActions';

const CallToActionSection = () => {
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState('');
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = () => {
    if (email.length > 100) {
      setErrors('Email must be less than 100 characters.');
      return;
    }

    if (!validateEmail(email)) {
      setErrors('Please enter a valid email address.');
      return;
    }

    dispatch(subscribe({ email }))
      .then(() => {
        setEmail('');
        setErrors('');
        setIsModalOpen(true);
      })
      .catch((error) => {
        console.error('Subscription failed:', error);
      });
  };

  return (
    <div className="relative pt-16 pb-32 bg-gradient-to-b from-white to-gray-50 ">
      {/* Background Blur and Accent Elements */}
      <div aria-hidden="true" className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 ">
        <div className="blur-[106px] h-56 bg-gradient-to-br from-blue-500 to-purple-400"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300"></div>
      </div>

      <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
        <div className="relative">
          {/* Header with User Images */}
          <div className="flex items-center justify-center space-x-2">
            <img
              loading="lazy"
              width="400"
              height="400"
              src="https://randomuser.me/api/portraits/women/34.jpg"
              alt="member photo"
              className="h-8 w-8 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="https://randomuser.me/api/portraits/women/45.jpg"
              alt="member photo"
              className="h-12 w-12 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="https://randomuser.me/api/portraits/men/60.jpg"
              alt="member photo"
              className="z-10 h-16 w-16 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="https://randomuser.me/api/portraits/women/32.jpg"
              alt="member photo"
              className="relative h-12 w-12 rounded-full object-cover"
            />
            <img
              loading="lazy"
              width="200"
              height="200"
              src="https://randomuser.me/api/portraits/men/34.jpg"
              alt="member photo"
              className="h-8 w-8 rounded-full object-cover"
            />
          </div>

          {/* Main Content */}
          <div className="mt-8 space-y-6 text-center md:w-8/12 lg:w-7/12 mx-auto">
            <h1 className="text-4xl font-extrabold text-gray-800 md:text-5xl">
              Join the GenAI Revolution
            </h1>
            <p className="text-xl text-gray-600 ">
              Subscribe to receive exclusive updates on our AI innovations and stay ahead in the world of generative AI.
            </p>

            {/* Subscription Input and Button */}
            <div className="flex flex-wrap justify-center gap-4">
              <input
                type="email"
                value={email}
                maxLength={100}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="w-full max-w-xs px-5 py-3 text-base text-gray-900 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-transform transform hover:scale-105"
              />
              <button
                onClick={handleSubscribe}
                className="inline-flex items-center justify-center px-6 py-3 text-md font-semibold text-white bg-gradient-to-r from-blue-500 to-purple-600 rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 transition-transform transform hover:scale-105 active:scale-95"
              >
                Subscribe Now
              </button>
            </div>

            {/* Error Message */}
            {errors && <p className="text-red-500 text-sm mt-2">{errors}</p>}
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold text-gray-800">Thank You for Subscribing!</h2>
        <p className="mt-4 text-gray-600">
          We'll keep you updated with the latest projects and innovations from GenAIBox. Stay tuned!
        </p>
      </Modal>
    </div>
  );
};

export default CallToActionSection;
