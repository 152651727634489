import React, { useState, useEffect } from 'react';
import { FaCookieBite } from 'react-icons/fa';

const CookieConsentBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Delay showing the banner until the page has loaded to avoid any performance issues.
  useEffect(() => {
    const consent = localStorage.getItem('genaiboxCookieConsent');
    
    // Show the banner only if no prior consent has been given or declined
    if (!consent) {
      // Delay the appearance of the banner to not block rendering
      setTimeout(() => {
        setIsVisible(true);
      }, 2000); // 2 seconds delay before showing the banner
    }
  }, []);

  // Handle the user accepting cookies
  const handleAccept = () => {
    localStorage.setItem('genaiboxCookieConsent', 'accepted');
    setIsVisible(false);
    initializeGoogleAnalytics();
  };

  // Handle the user declining cookies
  const handleDecline = () => {
    localStorage.setItem('genaiboxCookieConsent', 'declined');
    setIsVisible(false);
    removeNonEssentialCookies();
  };

  // Initialize Google Analytics after accepting cookies
  const initializeGoogleAnalytics = () => {
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        };
      i[r].l = 1 * new Date();
      a = s.createElement(o);
      m = s.getElementsByTagName(o)[0];
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    window.ga('create', 'YOUR_TRACKING_ID', 'auto'); // Replace with your GA tracking ID
    window.ga('send', 'pageview');
  };

  // Remove non-essential cookies when declined
  const removeNonEssentialCookies = () => {
    document.cookie = '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // Add more logic here if you need to remove additional cookies or prevent future cookies.
  };

  // If the banner is not visible, return null to avoid rendering anything.
  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-4 left-4 z-50">
      <div className="bg-white p-6 rounded-xl shadow-lg max-w-md w-full text-center flex flex-col items-center space-y-4">
        {/* Cookie Icon */}
        <FaCookieBite className="text-gray-500 text-3xl" aria-hidden="true" />

        {/* Cookie Consent Text */}
        <p className="text-gray-700">
          We use third-party cookies in order to personalize your site experience. By using this site, you agree to our{' '}
          <a href="/privacy-policy" className="text-blue-500 underline">
            Privacy Policy
          </a>
          .
        </p>

        {/* Accept and Decline Buttons */}
        <div className="flex space-x-4">
          <button
            onClick={handleDecline}
            className="px-4 py-1 rounded-full border border-gray-300 text-gray-600 hover:bg-gray-100 transition duration-300"
          >
            Decline
          </button>
          <button
            onClick={handleAccept}
            className="px-4 py-1 rounded-full bg-purple-600 text-white hover:bg-purple-700 transition duration-300"
          >
            Accept  
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
