  import React from 'react';
  import { FaCode, FaIndustry, FaSyncAlt, FaDatabase } from 'react-icons/fa';

  export const AboutUsSection = () => {
    return (
      <div className="relative bg-gray-50">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-24">
          <div className="grid gap-10 lg:grid-cols-2">
            
            {/* Left Side: Text and Benefits */}
            <div className="flex flex-col justify-center">
              <div className="max-w-xl mb-6">
                <h2 className="max-w-lg mb-6 text-3xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
                  Why Choose <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">GenAI Box</span>
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                  GenAI Box is a game-changing AI platform designed to streamline and automate your business operations. Our advanced AI solutions help you unlock new efficiencies, improve accuracy, and enhance productivity.
                </p>
              </div>
              <div className="grid gap-5 row-gap-8 sm:grid-cols-2">
                
                {/* Feature 1 */}
                <div className="bg-white border-l-4 border-blue-600 shadow-sm rounded-lg hover:shadow-lg transition">
                  <div className="h-full p-6 flex items-start">
                    <FaCode className="text-blue-600 w-16 h-16 -mt-2 mr-4" />
                    <div>
                      <h6 className="mb-2 font-semibold leading-5">Standard Code Practices</h6>
                      <p className="text-sm text-gray-700">
                        We follow industry best coding practices to ensure robust, scalable, and future-proof AI solutions.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Feature 2 */}
                <div className="bg-white border-l-4 border-blue-600 shadow-sm rounded-lg hover:shadow-lg transition">
                  <div className="h-full p-6 flex items-start">
                    <FaIndustry className="text-blue-600 w-12 h-12 mr-4" />
                    <div>
                      <h6 className="mb-2 font-semibold leading-5">Trusted by Leading Companies</h6>
                      <p className="text-sm text-gray-700">
                        Industry leaders trust GenAI Box to drive innovation and operational efficiency.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Feature 3 */}
                <div className="bg-white border-l-4 border-blue-600 shadow-sm rounded-lg hover:shadow-lg transition">
                  <div className="h-full p-6 flex items-start">
                    <FaDatabase className="text-blue-600 w-12 h-12 mr-4" />
                    <div>
                      <h6 className="mb-2 font-semibold leading-5">Accurate Data Extraction</h6>
                      <p className="text-sm text-gray-700">
                        Our AI ensures high accuracy in data extraction, allowing you to focus on what matters most.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Feature 4 */}
                <div className="bg-white border-l-4 border-blue-600 shadow-sm rounded-lg hover:shadow-lg transition">
                  <div className="h-full p-6 flex items-start">
                    <FaSyncAlt className="text-blue-600 w-16 h-16 -mt-2 mr-4" />
                    <div>
                      <h6 className="mb-2 font-semibold leading-5">Seamless Integration</h6>
                      <p className="text-sm text-gray-700">
                        GenAI Box integrates smoothly with your existing systems, making workflow enhancements hassle-free.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* Right Side: Image */}
            <div className="flex justify-center items-center">
              <img
                className="object-fill rounded-lg shadow-lg transform transition-transform hover:scale-105"
                src="images/about-1.jpg"
                alt="GenAI Box Technology"
              />
            </div>
          </div>
        </div>

        {/* Decorative Circle */}
        <div className="absolute bottom-0 left-0 w-32 h-32 rounded-full bg-blue-400 opacity-10 blur-3xl"></div>
      </div>
    );
  };
