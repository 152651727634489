import React, { useEffect, useState } from 'react';
import ProjectPage from './Project/ProjectPage';
import ProjectDetail from './Project/ProjectDetails';
import { getProjectDetails } from '../../redux/actions/projectActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Project = () => {
  const [projectDetails, setProjectDetails] = useState([]);
  const [projectCodeList, setProjectCodeList] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state || { id: null };  
  console.log('id', id);
  
  useEffect(() => {
    if(id) {
      dispatch(getProjectDetails(id)).then((data) => {
        if(data && data.project) {
          setProjectDetails(data.project); 
          setProjectCodeList(data.project_code);
        } 
      });
    }
  }, [id]);

  return (
    <div className="min-h-screen">
    {projectDetails && projectCodeList && projectCodeList.length > 0 && (<>
      <ProjectPage projectDetails={projectDetails} projectCodeList={projectCodeList} />
      <ProjectDetail projectDetails={projectDetails} />
    </>)}
    </div>
  );
};

export default Project;
