import React from 'react';
import { FaChartLine, FaReact, FaMobileAlt, FaBuffer } from 'react-icons/fa';
import { GoDatabase } from 'react-icons/go';
import { MdLockOutline } from 'react-icons/md';
import { TbFileInvoice } from 'react-icons/tb';
import { BiExport } from 'react-icons/bi';
import { IoSettingsOutline } from 'react-icons/io5';
import { GiNotebook } from "react-icons/gi";
import { RiSeoLine, RiFileSettingsLine } from "react-icons/ri";
import { LuExpand } from "react-icons/lu";


const ProjectTechnical = ({ technologies }) => {
  const iconMap = {
    FaChartLine: <FaChartLine className="text-blue-600 w-9 h-9 mx-auto" />,
    FaReact: <FaReact className="text-blue-600 w-10 h-10 mx-auto" />,
    FaMobileAlt: <FaMobileAlt className="text-blue-600 w-9 h-9 mx-auto" />,
    FaBuffer: <FaBuffer className="text-blue-600 w-10 h-10 mx-auto" />,
    GoDatabase: <GoDatabase className="text-blue-600 w-10 h-10 mx-auto" />,
    GiNotebook: <GiNotebook className="text-blue-600 w-10 h-10 mx-auto" />,
    RiSeoLine: <RiSeoLine className="text-blue-600 w-10 h-10 mx-auto" />,
    LuExpand: <LuExpand className="text-blue-600 w-10 h-10 mx-auto" />,
    RiFileSettingsLine: <RiFileSettingsLine className="text-blue-600 w-10 h-10 mx-auto" />,
    MdLockOutline: (
      <MdLockOutline className="text-blue-600 w-10 h-10 mx-auto" />
    ),
    TbFileInvoice: (
      <TbFileInvoice className="text-blue-600 w-10 h-10 mx-auto" />
    ),
    BiExport: <BiExport className="text-blue-600 w-10 h-10 mx-auto" />,
    IoSettingsOutline: (
      <IoSettingsOutline className="text-blue-600 w-10 h-10 mx-auto" />
    ),
  };

  const renderIcon = (iconName) => {
    return iconMap[iconName] || (
      <IoSettingsOutline className="text-blue-600 w-12 h-12 mx-auto" />
    );
  };

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-700 mb-6">
            Technical Specifications
          </h2>
          <p className="text-base text-gray-700">
            Understand the technical aspects of our solution.
          </p>
        </div>
        <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {technologies &&
            technologies.map((tech, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-lg transform transition hover:-translate-y-2"
              >
                {renderIcon(tech.icon)}
                <h3 className="mt-4 text-xl font-semibold text-gray-900 text-center">
                  {tech.name}
                </h3>
                <p className="mt-2 text-gray-700 text-center">
                  {tech.description}
                </p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectTechnical;
