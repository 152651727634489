import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../../Common/CartContext';
import { FILE_URL } from '../../../redux/env';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ProjectPage = ({ projectDetails, projectCodeList }) => {
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);

  const [isToastVisible, setToastVisible] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState([]);  

  useEffect(() => {
    if (projectCodeList.length > 0) {
      const firstCode = {
        id: projectCodeList[0].id,
        title: projectCodeList[0].title,
        price_regular_offer:
          projectDetails.price_regular_offer || 0,
        price_regular: projectDetails.price_regular || 0,
      };
      setSelectedCodes([firstCode]);
    }
  }, [projectDetails, projectCodeList]);
 

  const handleCheckboxChange = (codelist) => {
    setSelectedCodes((prevSelectedCodes) => {
      if (prevSelectedCodes.some((code) => code.id === codelist.id)) {
        return prevSelectedCodes.filter((code) => code.id !== codelist.id);
      } else {
        return [...prevSelectedCodes, codelist];
      }
    });
  }; 
  
  const handleAddToCart = () => {
    selectedCodes.forEach((selectedCode) => {
      addToCart({
        id: projectDetails.id,
        name: projectDetails.name,
        license: 'Regular License',
        title: selectedCode.title,
        price: selectedCode.price_regular_offer,
        image: projectDetails.image,
        oldPrice: selectedCode.price_regular,
        version: projectDetails.latest_version.version,
        version_id: projectDetails.latest_version.id,
        content_id: selectedCode.id,
      });
    });

    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000);
  };

  const handleBuyNow = () => {
    handleAddToCart();
    navigate('/checkout');
  };

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-4 lg:px-8">
        {isToastVisible && (
          <div className="fixed top-16 right-4 mt-4 mr-4 z-50">
            <div className="border bg-blue-50 border-blue-500 text-blue-500 px-6 py-2 rounded-md shadow-md">
              Item added to cart! <Link className="font-bold underline" to="/cart">View Cart</Link>
            </div>
          </div>
        )}
        {projectDetails && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div>
              <div className="relative group rounded-xl shadow-xl ">
                <img
                  src={FILE_URL + projectDetails.image}
                  alt={projectDetails.name}
                  className="rounded-xl transition-all duration-500"
                />
                <a
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={projectDetails.project_url}
                >
                  <div>
                    <div className="text-white text-center">
                      <img
                        src="/images/preview_icon.png"
                        alt="Live Preview"
                        className="w-16 h-16 mx-auto mb-4"
                      />
                      <p className="text-lg font-semibold">Live Preview</p>
                    </div>
                  </div>
                </a>
                {/* Thumbnails */}
                
              </div>

              <div className='text-center justify-center mt-4'>
                <a
                  href={projectDetails.project_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-auto sm:w-auto inline-flex items-center justify-center rounded-full py-1 px-3 text-md focus:outline-none border border-gray-400 bg-white text-gray-400 hover:bg-blue-50 hover:text-blue-500 hover:border-blue-500 transition-all duration-200"
                >
                  Live Preview
                </a>
              </div> 
            </div>
            <div className="lg:pl-8">
              <div className="max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {projectDetails.name}
                </h2>
                <div className="flex flex-wrap items-center mt-2 text-gray-500">
                  <h2 className="font-semibold tracking-tight">
                    Version {projectDetails.latest_version?.version}.0
                  </h2>
                  <Link
                    to="/regular-license/"
                    data-tooltip-id="licenseTooltip"
                    data-tooltip-content="Use, by you or one client, in a single end product which end users are not charged for. The total price includes the item price and a buyer fee."
                    className="ml-4 text-blue-500 hover:underline"
                  >
                    <b>License</b> - Regular License
                  </Link>
                  <Tooltip
                    id="licenseTooltip"
                    place="bottom"
                    effect="solid"
                    style={{
                      color: 'white',
                      padding: '10px',
                      borderRadius: '10px',
                      maxWidth: '400px',
                    }}
                  />
                </div>
                <p className="mt-4 text-lg leading-8 text-gray-700">
                  {projectDetails.description}
                </p>
                <div className="flex flex-wrap mt-4">
                  {projectDetails.technology &&
                    projectDetails.technology
                      .split(',')
                      .map((tech, index) => (
                        <span
                          key={index}
                          className="bg-gradient-to-r from-blue-100 to-gray-200 font-semibold mr-2 mb-2 py-1 px-3 rounded-full text-sm text-gray-700"
                        >
                          {tech}
                        </span>
                      ))}
                </div>

                {/* Checkboxes for project codes */}
                <div className="mt-6">
                  <h3 className="text-lg font-semibold mb-4">
                    Select Code Technology:
                  </h3>
                  {projectCodeList &&
                    projectCodeList.map((codelist, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between mb-3"
                      >
                        <label
                          htmlFor={`codelist-${index}`}
                          className="flex items-center cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            id={`codelist-${index}`}
                            className="form-checkbox h-5 w-5 text-blue-600"
                            checked={selectedCodes.some(
                              (code) => code.id === codelist.id
                            )}
                            onChange={() =>
                              handleCheckboxChange({
                                id: codelist.id,
                                title: codelist.title,
                                price_regular_offer:
                                  projectDetails.price_regular_offer,
                                price_regular:
                                  projectDetails.price_regular,
                              })
                            }
                          />
                          <span className="ml-3 text-gray-700 font-medium">
                            {codelist.title}
                          </span>
                        </label>
                        <span className="text-xl font-bold text-blue-600">
                          {projectDetails.price_regular && (
                            <span className="text-sm text-gray-500 font-semibold line-through mr-2">
                              ${projectDetails.price_regular}
                            </span>
                          )}
                          ${projectDetails.price_regular_offer}
                        </span>
                      </div>
                    ))}
                </div>

                <div className="mt-8 flex flex-col sm:flex-row gap-4">
                  <button
                    onClick={handleAddToCart}
                    className="w-full sm:w-auto inline-flex items-center justify-center rounded-full py-3 px-6 text-md font-semibold border border-transparent focus:outline-none bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:from-blue-700 hover:to-purple-700 transition-all duration-200"
                  >
                    Add to Cart
                  </button>
                  <button
                    onClick={handleBuyNow}
                    className="w-full sm:w-auto inline-flex items-center justify-center rounded-full py-3 px-6 text-md font-semibold focus:outline-none border border-blue-600 bg-white text-blue-600 hover:bg-blue-50 transition-all duration-200"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProjectPage;
