import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ServiceHeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section className="relative py-16 md:py-28 text-center bg-gradient-to-r from-blue-50 via-white to-blue-50 overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0">
        <div className="h-full w-full bg-gradient-to-b from-blue-50 via-white to-blue-50 opacity-90"></div>
      </div>

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Section Title */}
        <h1 
          data-aos="fade-up"
          className="text-3xl md:text-6xl max-w-4xl mx-auto font-extrabold text-gray-900 mb-6"
        >
          Empower Your Business with <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">GenAI Box</span>
        </h1>

        {/* Section Description */}
        <p 
          data-aos="fade-up"
          data-aos-delay="200"
          className="text-md md:text-xl font-semibold text-gray-600 max-w-2xl mx-auto mb-8">
          Leverage the power of our AI-driven solutions to automate workflows, optimize business processes, and gain actionable insights. 
          With GenAI Box, you’ll experience unparalleled efficiency and data-driven decision-making that transforms your business operations.
        </p> 
      </div>
    </section>
  );
};

export default ServiceHeroSection;
