import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
      <section className="relative py-16 md:py-28 text-center bg-gradient-to-r from-blue-50 via-white to-blue-50 overflow-hidden">
        {/* Background Decorative Elements */}
        <div className="absolute inset-0">
          <div className="h-full w-full bg-gradient-to-b from-blue-50 via-white to-blue-50 opacity-90"></div>
        </div>
        
        {/* Container */}
        <div className="container relative z-10 mx-auto px-6 md:px-12 lg:px-16">
          <h1 
          data-aos="fade-up"
          className="text-3xl md:text-6xl max-w-4xl mx-auto font-extrabold text-gray-900 mb-6">
            Elevate Your Business with <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">GenAI Box</span>
          </h1>
          <p 
          data-aos="fade-up"
          data-aos-delay="200"
          className="text-md md:text-xl font-semibold text-gray-600 max-w-2xl mx-auto mb-8">
            We harness the power of cutting-edge generative AI to optimize workflows, boost innovation, and redefine the future of automation. 
          </p> 
        </div>

        {/* Decorative Shapes */}
        <div className="absolute bottom-0 left-0 w-32 h-32 rounded-full bg-blue-300 opacity-20 blur-2xl"></div>
        <div className="absolute top-0 right-0 w-48 h-48 rounded-full bg-blue-400 opacity-20 blur-2xl"></div>
      </section>
    </>
  );
};

export default HeroSection;
