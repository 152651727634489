  import React, { useContext, useState, useEffect } from 'react';
  import { Link } from 'react-router-dom';
  import { CartContext } from '../../../Common/CartContext';
  import { useDispatch } from 'react-redux';
  import { decryptData } from '../../../utils/encryption';
  import { addOrderAndPayment, razorpayOrder } from '../../../redux/actions/orderActions';
  import {
    PayPalScriptProvider,
    PayPalButtons,
  } from '@paypal/react-paypal-js';
  import '../../../style/Style.css';
  import axiosInstance from '../../../Common/axiosInstance';
  import ExcitingMessageModal from './ExcitingMessageModal';
  import loadScript from '../../../utils/loadScript'; // Utility function to load external scripts
  import { FaLock } from 'react-icons/fa';
  import { useNavigate } from 'react-router-dom';

  const CheckoutPage = () => {
    const { cart, clearCart } = useContext(CartContext);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [orderData, setOrderData] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('razorpay');
    const user = decryptData(localStorage.getItem('H$S%#PR32S'));
    const navigate = useNavigate();

    const [shippingInfo, setShippingInfo] = useState(() => {
      const savedShippingInfo = sessionStorage.getItem('shippingInfo');
      return savedShippingInfo
        ? JSON.parse(savedShippingInfo)
        : {
            user: user.id,
            address: '',
            city: '',
            zip_code: '',
            country: '',
          };
    });

    const [errors, setErrors] = useState({});

    // Updated total amount based on quantity
    const totalAmount = cart
      ? cart.reduce((acc, item) => acc + item.price * item.quantity, 0)
      : 0;

    useEffect(() => {
      if (user && shippingInfo.address === '') {
        fetchShippingAddress();
      }
    }, []);

    useEffect(() => {
      if (user && shippingInfo.address !== '') {
        sessionStorage.setItem('shippingInfo', JSON.stringify(shippingInfo));
      }
    }, [shippingInfo]);

    useEffect(() => {
      createOrder();
    }, []);

    const fetchShippingAddress = async () => {
      try {
        const response = await axiosInstance.get('/get-shipping-address/');
        setShippingInfo({
          user: user.id,
          address: response.data.address || '',
          city: response.data.city || '',
          zip_code: response.data.zip_code || '',
          country: response.data.country || '',
        });
      } catch (error) {
        console.error('Error fetching shipping address', error);
      }
    };

    const createOrder = async () => {
      if(totalAmount > 0) {
        dispatch(razorpayOrder(totalAmount)).then(res => {
          setOrderData(res); // Store order data for payment initiation
        })
      }
    };

    const handleShippingChange = (e) => {
      const { name, value } = e.target;
      setShippingInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    };

    const validateForm = () => {
      const newErrors = {};
      if (!shippingInfo.address) newErrors.address = 'Address is required';
      if (!shippingInfo.city) newErrors.city = 'City is required';
      if (!shippingInfo.zip_code) newErrors.zip_code = 'Zip Code is required';
      if (!shippingInfo.country) newErrors.country = 'Country is required';
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handlePlaceOrder = async (paymentData, paymentMethod) => {
      try {
        const orderData = {
          shippingInfo: shippingInfo,
          products: cart,
          totalAmount,
          payment_status: 'paid',
          payment: {
            amount: totalAmount,
            payment_method: paymentMethod,
            payment_status: 'paid',
            response: paymentData,
          },
        };
        const response = await dispatch(addOrderAndPayment(orderData));
        if (response && response.success) {
          clearCart(); // Clear cart after successful order
          setIsModalOpen(true); // Show modal with success message
          navigate('/payment-success', { state: {status: true} });
        } else {
          setErrorMessage('Payment failed. Please try again.');
          console.error('Order or payment creation failed', response);
        }
      } catch (error) { 
        setErrorMessage('Payment failed. Please try again.');
        console.error('Order or payment creation failed', error);
      }
    };

    // Razorpay Payment Integration
    const handleRazorpayPayment = async () => {
      if (!validateForm()) {
        setErrorMessage('Please fill all required shipping fields.');
        return;
      }

      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
      }

      try {
        if (!orderData) {
          createOrder();
        }

        const { amount, order_id, currency, razorpay_key_id } = orderData;
        console.log('orderData', orderData)
        const options = {
          key: razorpay_key_id, // Replace with your Razorpay key ID
          amount: amount,
          currency: currency,
          name: 'GenAI Box',
          description: 'Razorpay Payment',
          order_id: order_id,
          handler: async function (response) {
            setOrderData(null)
            const paymentData = {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            };
            await handlePlaceOrder(paymentData, 'razorpay');
          },
          prefill: {
            name: user.name,
            email: user.email,
            contact: '', // Optional: Add user's phone number
          },
          notes: {
            address: shippingInfo.address,
          },
          theme: {
            color: '#3399cc',
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
          setErrorMessage('Payment failed. Please try again.');
          console.error('Payment failed', response.error);
        });
        paymentObject.open();
      } catch (error) {
        console.error('Error in Razorpay payment', error);
        setErrorMessage('Payment failed. Please try again.');
      }
    };

    return (
      <div className="min-h-screen bg-gray-100 py-10">
        <div className="container mx-auto px-4">
          {errorMessage && (
            <div
              className="bg-red-100 border-t-4 border-red-400 text-red-700 px-4 py-4 rounded relative mb-4 shadow-md"
              role="alert"
            >
              <strong className="font-bold">Payment Error! </strong>
              <span className="block sm:inline">{errorMessage}</span>
              <button
                onClick={() => setErrorMessage('')}
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
              >
                <svg
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 1 1-1.697 1.697L10 11.819l-2.651 3.03a1.2 1.2 0 1 1-1.697-1.698l2.758-3.15-2.759-3.15a1.2 1.2 0 1 1 1.697-1.698L10 8.183l2.651-3.03a1.2 1.2 0 1 1 1.697 1.698l-2.758 3.15 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </button>
            </div>
          )}
          <nav className="text-gray-700 mb-6">
            <Link to="/" className="text-gray-700 font-semibold hover:underline">
              Home
            </Link>{' '}
            / <span className="text-blue-700 font-semibold">Checkout</span>
          </nav>
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <div className="col-span-1 lg:col-span-8 bg-white p-8 rounded-lg shadow-md">
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-6">Billing Address</h3>
                <form className="space-y-6">
                  {/* Shipping Address Form */}
                  {/* ... (same as before) */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="address"
                        className="block text-gray-700 font-semibold mb-2"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        value={shippingInfo.address}
                        placeholder="Enter your address"
                        maxLength={300}
                        onChange={handleShippingChange}
                        onBlur={validateForm}
                        className={`w-full px-4 py-3 rounded-lg bg-gray-100 border ${
                          errors.address ? 'border-red-500' : 'border-gray-200'
                        } placeholder-gray-500 text-sm focus:outline-none focus:border-blue-500`}
                      />
                      {errors.address && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.address}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="city"
                        className="block text-gray-700 font-semibold mb-2"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={shippingInfo.city}
                        placeholder="Enter your city"
                        maxLength={50}
                        onChange={handleShippingChange}
                        onBlur={validateForm}
                        className={`w-full px-4 py-3 rounded-lg bg-gray-100 border ${
                          errors.city ? 'border-red-500' : 'border-gray-200'
                        } placeholder-gray-500 text-sm focus:outline-none focus:border-blue-500`}
                      />
                      {errors.city && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.city}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="zip_code"
                        className="block text-gray-700 font-semibold mb-2"
                      >
                        Zip Code
                      </label>
                      <input
                        type="text"
                        name="zip_code"
                        value={shippingInfo.zip_code}
                        placeholder="Enter your zip code"
                        maxLength={10}
                        onChange={handleShippingChange}
                        onBlur={validateForm}
                        className={`w-full px-4 py-3 rounded-lg bg-gray-100 border ${
                          errors.zip_code ? 'border-red-500' : 'border-gray-200'
                        } placeholder-gray-500 text-sm focus:outline-none focus:border-blue-500`}
                      />
                      {errors.zip_code && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.zip_code}
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="country"
                        className="block text-gray-700 font-semibold mb-2"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        name="country"
                        value={shippingInfo.country}
                        placeholder="Enter your country"
                        maxLength={50}
                        onChange={handleShippingChange}
                        onBlur={validateForm}
                        className={`w-full px-4 py-3 rounded-lg bg-gray-100 border ${
                          errors.country ? 'border-red-500' : 'border-gray-200'
                        } placeholder-gray-500 text-sm focus:outline-none focus:border-blue-500`}
                      />
                      {errors.country && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.country}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="mb-8">
                <h3 className="text-2xl font-semibold mb-6">Payment Method</h3>
                <div className="mb-4">
                  {/* Payment Method Selection */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div
                      className={`border rounded-lg p-4 cursor-pointer ${
                        selectedPaymentMethod === 'razorpay'
                          ? 'border-blue-600'
                          : 'border-gray-300'
                      }`}
                      onClick={() => setSelectedPaymentMethod('razorpay')}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-blue-600"
                          name="paymentMethod"
                          value="razorpay"
                          checked={selectedPaymentMethod === 'razorpay'}
                          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                        />
                        <img
                          src="https://razorpay.com/assets/razorpay-glyph.svg"
                          alt="Razorpay"
                          className="h-8 ml-4"
                        />
                        <p className='text-gray-700 font-bold'>Razorpay</p>
                        </div>
                      <p className="mt-2 text-gray-600">
                        Pay securely using Razorpay. All major credit and debit cards accepted.
                      </p>
                    </div>
                    <div
                      className={`border rounded-lg p-4 cursor-pointer ${
                        selectedPaymentMethod === 'paypal'
                          ? 'border-blue-600'
                          : 'border-gray-300'
                      }`}
                      onClick={() => setSelectedPaymentMethod('paypal')}
                    >
                      <div className="flex items-center">
                        <input
                          type="radio"
                          className="form-radio h-5 w-5 text-blue-600"
                          name="paymentMethod"
                          value="paypal"
                          checked={selectedPaymentMethod === 'paypal'}
                          onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                        />
                        <img
                          src="https://www.paypalobjects.com/webstatic/icon/pp258.png"
                          alt="PayPal"
                          className="h-8 ml-4"
                        />
                        <p className='text-gray-700 font-bold'>PayPal</p>
                        </div>
                      <p className="mt-2 text-gray-600">
                        Pay securely using PayPal. You can pay with your credit card if you don’t have a PayPal account.
                      </p>
                    </div>
                  </div>
                </div>
                {/* Payment Method Components */}
                {selectedPaymentMethod === 'paypal' && (
                  <div className="mt-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-gray-700 mb-4">
                        You will be redirected to PayPal to complete your purchase securely.
                      </p>
                      <div className="flex justify-center">
                        <PayPalScriptProvider
                          options={{ 'client-id': 'AWPunCaxBAF8WPxKy_OOu28pPDXQ-EUH2eT50l33LQ6gYV2dYOJP_mTTDQYjfeuC7ktyp5b__u9poYts' }}
                        >
                          <PayPalButtons
                            style={{
                              layout: 'vertical',
                              color: 'blue',
                              shape: 'pill',
                              label: 'pay',
                            }}
                            createOrder={(data, actions) => {
                              return actions.order.create({
                                purchase_units: cart.map((item, index) => ({
                                  reference_id: (item.content_id + '_' + index).toString(),
                                  amount: {
                                    currency_code: 'USD',
                                    value: (item.price * item.quantity).toFixed(2),
                                  },
                                  description: item.name + ' ' + item.title,
                                })),
                              });
                            }}
                            onApprove={(data, actions) => {
                              return actions.order.capture().then((details) => {
                                handlePlaceOrder(details, 'paypal');
                              });
                            }}
                            onError={(err) => {
                              setErrorMessage('Payment failed. Please try again.');
                              console.error('Payment error', err);
                            }}
                          />
                        </PayPalScriptProvider>
                      </div>
                    </div>
                  </div>
                )}
                {selectedPaymentMethod === 'razorpay' && (
                  <div className="mt-6">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <p className="text-gray-700 mb-4">
                        You will be redirected to Razorpay to complete your purchase securely.
                      </p>
                      <div className="flex justify-center">
                        <button
                          onClick={handleRazorpayPayment}
                          className="bg-blue-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-blue-700 focus:outline-none"
                        >
                          Pay with Razorpay
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-6 flex items-center text-gray-600">
                  <FaLock className="mr-2 text-green-600" />
                  <p>
                    All transactions are secure and encrypted.
                  </p>
                </div>
              </div>
            </div>

            {/* Order Summary */}
            <div className="col-span-1 lg:col-span-4">
              <div className="bg-white p-8 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold mb-6">Order Summary</h3>
                <div className="space-y-6">
                  {cart.map((item, index) => (
                    <div
                      key={`${item.id}-${item.content_id}-${item.uniqueId}-${index}`}
                      className="flex justify-between items-center"
                    >
                      <div>
                        <p className="font-semibold text-gray-700">
                          {item.name}
                        </p>
                        <p className="text-gray-500 text-sm">
                          {item.title}
                        </p>
                        <p className="text-gray-500 text-sm">
                          Quantity: {item.quantity}
                        </p>
                        <p className="text-gray-500 text-sm">
                          License: {item.license}
                        </p>
                      </div>
                      <p className="font-semibold text-gray-700">
                        ${(item.price * item.quantity).toFixed(2)}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex justify-between mt-6 border-t pt-6">
                  <p className="font-semibold text-xl text-gray-800">Total</p>
                  <p className="font-semibold text-xl text-gray-800">
                    ${totalAmount.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <ExcitingMessageModal onClose={() => setIsModalOpen(false)} />
        )}
      </div>
    );
  };

  export default CheckoutPage;
