import React from 'react';
import { Link } from 'react-router-dom';
import { FaLightbulb, FaRocket, FaCode, FaUsers } from 'react-icons/fa';

const HomeAboutUs = () => {
  return (
    <section className="relative py-16 bg-gradient-to-r from-white via-blue-50 to-blue-50 overflow-hidden">
      {/* Decorative Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[500px] h-[500px] bg-blue-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob"></div>
        <div className="absolute bottom-0 left-20 w-[400px] h-[400px] bg-purple-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30 animate-blob animation-delay-2000"></div>
      </div>

      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid items-center grid-cols-1 md:grid-cols-2 gap-12">
          {/* Left Side Content */}
          <div data-aos="fade-right">
            <h2 className="text-4xl font-extrabold leading-tight text-gray-900 sm:text-5xl lg:text-6xl">
              Innovate with <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Generative AI</span>
            </h2>
            <p className="mt-6 text-lg text-gray-700">
              At <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">GenAIBox</span>, we revolutionize businesses by harnessing the power of generative AI. Our mission is to push the boundaries of technology to deliver innovative solutions that drive efficiency, creativity, and growth.
            </p>

            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="flex items-start">
                <FaLightbulb className="text-blue-600 text-2xl mr-4" />
                <div>
                  <h4 className="text-xl font-semibold text-gray-800">Innovative Solutions</h4>
                  <p className="mt-2 text-gray-600">
                    Pioneering AI technologies that redefine possibilities.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <FaRocket className="text-blue-600 text-2xl mr-4" />
                <div>
                  <h4 className="text-xl font-semibold text-gray-800">Scalable Growth</h4>
                  <p className="mt-2 text-gray-600">
                    Empowering businesses to scale rapidly with AI-driven insights.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <FaCode className="text-blue-600 text-2xl mr-4" />
                <div>
                  <h4 className="text-xl font-semibold text-gray-800">Quality Code</h4>
                  <p className="mt-2 text-gray-600">
                    Adhering to industry best practices for robust solutions.
                  </p>
                </div>
              </div>
              <div className="flex items-start">
                <FaUsers className="text-blue-600 text-2xl mr-4" />
                <div>
                  <h4 className="text-xl font-semibold text-gray-800">Expert Team</h4>
                  <p className="mt-2 text-gray-600">
                    A dedicated team committed to your success.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <Link
                to="/contact"
                className="inline-block px-8 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white font-semibold rounded-full hover:from-blue-700 hover:to-purple-700 transition duration-300"
              >
                Connect with Us
              </Link>
            </div>
          </div>

          {/* Right Side Image */}
          <div className="relative" data-aos="fade-left">
            <img
              className="relative w-full bg-gradient-to-r from-blue-800 to-purple-800 rounded-lg shadow-lg"
              src="images/business-woman.png"
              // src="https://cdn.rareblocks.xyz/collection/celebration/images/team/1/business-woman.png"
              alt="Our Team"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAboutUs;
