import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactMessage } from '../../../redux/actions/authActions';
import Modal from '../../../Components/Modal';
import { FaMapMarkerAlt, FaEnvelope, FaUsers } from 'react-icons/fa';
import { useForm } from 'react-hook-form';

const ContactUs = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      await dispatch(contactMessage(data));
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error('Failed to submit your message. Please try again.');
      toast.error('Failed to submit your message. Please try again.');
    }
  };

  const handleCloseModal = () => {
    setIsSubmitted(false);
  };

  return (
    <section className="bg-gray-50 py-16 dark:bg-gray-900">
      <ToastContainer />
      <div className="container mx-auto px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Contact Information Sectio  n */}
          <div className="space-y-8">
            <div className="mb-6">
              <h3 className="inline-block px-3 py-1 mb-4 text-sm tracking-wider text-blue-600 font-bold uppercase rounded-full bg-blue-50">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">How can we help you?</span>
              </h3>
              <h1 className="text-2xl md:text-4xl font-semibold text-gray-800 capitalize dark:text-white">
                Have a project in mind? Let's connect!
              </h1>
              <p className="mt-6 text-gray-700 dark:text-gray-400">
                We're here to assist you with all your AI needs. Reach out to us, and let’s discuss how we can help your business grow with GenAI Box!
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-start space-x-4 my-6">
                <FaMapMarkerAlt className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">USA</h4>
                  <p className="text-gray-700 dark:text-gray-400">123 Tech Lane, Silicon Valley, CA 94043</p>
                </div>
              </div>
              <div className="flex items-start space-x-4 my-6">
                <FaEnvelope className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Send a message</h4>
                  <p className="text-gray-700 dark:text-gray-400">contact@genaibox.io</p>
                </div>
              </div>
              <div className="flex items-start space-x-4 my-6">
                <FaMapMarkerAlt className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">India</h4>
                  <p className="text-gray-700 dark:text-gray-400">ITL IT Park, Bengaluru, Karnataka, 560066</p>
                </div>
              </div>
              <div className="flex items-start space-x-4 my-6">
                <FaUsers className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                <div>
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Join our team</h4>
                  <p className="text-gray-700 dark:text-gray-400">hire@genaibox.io</p>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Form Section */}
          <div>
            <div className="max-w-lg px-8 py-10 mx-auto bg-white rounded-xl shadow-lg dark:bg-gray-900">
              <h3 className="inline-block px-3 py-1 mb-4 text-sm tracking-wider text-blue-600 font-bold uppercase rounded-full bg-blue-50">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Get in touch with us</span>
              </h3>
              <h1 className="text-2xl md:text-3xl font-semibold text-gray-800 capitalize dark:text-white mb-6">
                How can we help you?
              </h1>
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Your Name*</label>
                    <input
                      type="text"
                      {...register('name', {
                        required: 'Name is required',
                        maxLength: 50,
                      })}
                      placeholder="What's your name?"
                      className="block w-full px-5 py-3 mt-2 bg-gray-50 border border-gray-300 rounded-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email*</label>
                    <input
                      type="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: 'Invalid email address',
                        },
                        maxLength: 100,
                      })}
                      placeholder="Your email address"
                      className="block w-full px-5 py-3 mt-2 bg-gray-50 border border-gray-300 rounded-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Phone</label>
                    <input
                      type="text"
                      {...register('phone', {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'Invalid phone number',
                        },
                        maxLength: 15,
                      })}
                      placeholder="Your phone number"
                      className="block w-full px-5 py-3 mt-2 bg-gray-50 border border-gray-300 rounded-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {errors.phone && <span className="text-red-500 text-sm">{errors.phone.message}</span>}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Subject</label>
                    <input
                      type="text"
                      {...register('subject', {
                        maxLength: 100,
                      })}
                      placeholder="How can we help?"
                      className="block w-full px-5 py-3 mt-2 bg-gray-50 border border-gray-300 rounded-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                    {errors.subject && <span className="text-red-500 text-sm">{errors.subject.message}</span>}
                  </div>
                </div>
                <div>
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Your Message</label>
                  <textarea
                    {...register('message', {
                      maxLength: 400,
                    })}
                    className="block w-full h-32 px-5 py-3 mt-2 bg-gray-50 border border-gray-300 rounded-md dark:bg-gray-900 dark:border-gray-700 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Tell us more about your project"
                  ></textarea>
                  {errors.message && <span className="text-red-500 text-sm">{errors.message.message}</span>}
                </div>
                <button className="w-full py-3 mt-6 font-semibold text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-full shadow-lg hover:from-blue-700 hover:to-purple-700 transition-transform transform hover:scale-105 focus:ring-blue-500 focus:ring-offset-2">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for success message */}
      <Modal isOpen={isSubmitted} onClose={handleCloseModal}>
        <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
        <p className="text-gray-700 mb-4">Your message has been successfully sent. We'll get back to you soon!</p>
        <button
          onClick={handleCloseModal}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Close
        </button>
      </Modal>
    </section>
  );
};

export default ContactUs;
