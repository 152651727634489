  import React, { useState } from 'react';
  import ProjectDescription from './ProjectDescription';
  import ProjectTechnical from './ProjectTechnical';
  import ProjectFaq from './ProjectFaq';
  import ProjectReview from './ProjectReview';

  const ProjectDetail = ({ projectDetails }) => {
    const [activeTab, setActiveTab] = useState(0);

    const useCases = [
      {
        title: 'Project Details',
        content:
          projectDetails && (
            <>
            <ProjectDescription
              fullDescription={projectDetails.description_detail}
              images={projectDetails.images}
            />
            <ProjectTechnical technologies={projectDetails.technologies} />
            <ProjectReview reviews={projectDetails.reviews} />
            <ProjectFaq faqs={projectDetails.faqs} />
            </>
          ),
      },
      {
        title: 'Technical Specifications',
        content:
          projectDetails && (
            <ProjectTechnical technologies={projectDetails.technologies} />
          ),
      },
      {
        title: 'Reviews',
        content:
          projectDetails && <ProjectReview reviews={projectDetails.reviews} />,
      },
      {
        title: 'FAQ',
        content: projectDetails && <ProjectFaq faqs={projectDetails.faqs} />,
      },
    ];

    return (
      <section className="bg-gray-50 p-0 md:py-12">
        <div className="container mx-auto px-0 md:px-4">
          <div className="bg-white rounded-lg shadow-md">
            <div className="flex overflow-x-auto border-b">
              {useCases.map((useCase, index) => (
                <button
                  key={index}
                  className={`px-6 py-4 text-md font-semibold whitespace-nowrap focus:outline-none ${
                    activeTab === index
                      ? 'text-blue-600 border-b-2 border-blue-600'
                      : 'text-gray-600 hover:text-blue-600'
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {useCase.title}
                </button>
              ))}
            </div>
            <div className="p-0 md:p-6">{useCases[activeTab].content}</div>
          </div>
        </div>
      </section>
    );
  };

  export default ProjectDetail;
