import React from 'react';
import HeroSection from './About/HeroSection';
import TeamSection from './About/TeamSection';
import StatisticsSection from './About/StorySection';
import { AboutUsSection } from './About/AboutusSection';
import CallToAction from './About/CallToAction';
import MissionVisionSection from './About/MessionStatement';
import CoreValuesSection from './About/CoreValuesSection';

const About = () => {
  return (
    <> 
      <HeroSection />
      <AboutUsSection />
      <MissionVisionSection /> 
      {/* <StatisticsSection /> */}
      <CoreValuesSection /> 
      {/* <TeamSection />  */}
      <CallToAction />
    </>
  );
};

export default About;
