import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../../style/Style.css';
import '../../../style/ModalStyle.css';
import { FaDownload, FaCheckCircle } from 'react-icons/fa';

const PaymentSuccess = () => {
  const location = useLocation();
  const paymentStatus = location.state?.status;
  const navigate = useNavigate();

  useEffect(() => {
    if (!paymentStatus) {
      navigate(-1);
    }
  }, [paymentStatus, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      {paymentStatus && (
        <div className="bg-white m-8 p-8 md:p-12 rounded-lg shadow-lg text-center max-w-lg w-full">
          
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Payment Successful!</span>
          </h1>
          <p className="text-gray-600 mb-6">
            Thank you for your purchase. You have successfully acquired the{' '}
            <span className="font-semibold">GenAI Box</span> project!
          </p>
          <div className="flex justify-center mb-6">
            {/* Success Image */}
            <img
              src="./images/success-2.png" // Replace with your image path
              alt="Payment Successful"
              className="h-60"
            />
          </div>
          <p className="text-gray-600 mb-8">
            We are excited to have you on board. Click the button below to download your project and get started.
          </p>
          <Link
            to="/download"
            className="inline-flex items-center rounded-full justify-center py-3 px-6 text-sm sm:text-base font-semibold bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-blue-600 hover:to-purple-700 shadow-lg transform transition-transform"
              >
            <FaDownload className="mr-2" />
            Download Project
          </Link>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
