import React from 'react';
import ContactUs from './Contact/ContactUs';
import ContactHero from './Contact/ContactHero';
import FAQSection from './Home/FAQSection';
import ContactFAQ from './Contact/FaqContact';

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactUs />
      <FAQSection />
      {/* <ContactFAQ /> */}
    </>
  );
};

export default Contact;
