import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const faqs = [
  {
    question: "What is GenAIBox and how does it work?",
    answer:
      "GenAIBox is an all-in-one platform offering generative AI projects and services. It allows businesses to integrate advanced AI solutions like content generation, image synthesis, and data analysis into their operations seamlessly.",
  },
  {
    question: "How can I purchase a generative AI project?",
    answer:
      "You can browse our selection of AI projects on our website. Once you find a project that suits your needs, you can purchase it directly or contact our team for a customized solution.",
  },
  {
    question: "Do you offer customization for AI projects?",
    answer:
      "Yes, we offer customization services to tailor our AI projects to your specific business requirements. Our team of experts will work closely with you to develop a solution that fits your needs.",
  },
  {
    question: "What kind of support do you provide after purchase?",
    answer:
      "We provide comprehensive support including setup assistance, troubleshooting, and regular updates to ensure your AI projects run smoothly and effectively.",
  },
  {
    question: "Is there a trial period available for your services?",
    answer:
      "Yes, we offer a $1 trial for our premium AI services, allowing you to experience the benefits before making a full commitment.",
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-6 sm:px-12 lg:px-16 max-w-4xl">
        {/* Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Find answers to common questions about our generative AI projects and services.
          </p>
        </div>

        {/* FAQ List */}
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="border-b border-gray-200 pb-4"
            >
              <button
                className="w-full flex justify-between items-center text-left focus:outline-none"
                onClick={() => toggleFAQ(index)}
              >
                <span className="text-lg font-medium text-gray-800">
                  {faq.question}
                </span>
                {activeIndex === index ? (
                  <ChevronUpIcon className="w-6 h-6 text-blue-600" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6 text-blue-600" />
                )}
              </button>
              <AnimatePresence initial={false}>
                {activeIndex === index && (
                  <motion.div
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { height: 'auto', opacity: 1 },
                      collapsed: { height: 0, opacity: 0 },
                    }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                    className="overflow-hidden mt-2"
                  >
                    <p className="text-gray-600">{faq.answer}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
