import React, { useContext, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { CartContext } from '../../../Common/CartContext';
import { Link, useNavigate } from 'react-router-dom';
import { FILE_URL } from '../../../redux/env';

const CartPage = () => {
  const [codeError, setCodeError] = useState(false);
  const [codeInput, setCodeInput] = useState('');
  const { cart, removeFromCart, addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  console.log('cart', cart);
  
  // Calculate the total amount for all items in the cart
  const totalAmount = cart
    ? cart.reduce((acc, item) => acc + item.price * item.quantity, 0)
    : 0;

  // Handle the checkout process
  const checkOut = () => {
    localStorage.setItem('previous_url', '/checkout');
    navigate('/checkout');
  };

  // Handle increasing quantity
  const increaseQuantity = (item) => {
    addToCart({ ...item });
  };

  // Handle decreasing quantity
  const decreaseQuantity = (item) => {
    if (item.quantity > 1) {
      const updatedItem = { ...item, quantity: item.quantity - 1 };
      removeFromCart(item.uniqueId);
      setTimeout(() => addToCart(updatedItem), 0);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-10">
      <div className="container mx-auto p-4">
        <nav className="text-gray-700 mb-4">
          <Link to="/" className="text-gray-700 font-semibold hover:underline">
            Home
          </Link>{' '}
          /{' '}
          <span className="text-blue-700 font-semibold">Cart</span>
        </nav>

        {cart && cart.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
            <div className="col-span-1 md:col-span-8">
              {cart.map((item, index) => (
                <div
                  key={`${item.id}-${item.content_id}-${item.uniqueId}-${index}`}
                  className="flex flex-col md:flex-row p-4 bg-white rounded-lg shadow-md mb-4"
                >
                  <div className="md:flex items-center md:w-5/6">
                    <img
                      src={FILE_URL + item.image}
                      alt={item.name}
                      className="w-full md:w-32 md:h-32 p-2 border-2 border-gray-200 rounded-md object-cover"
                    />
                    <div className="ml-0 md:ml-4 mt-4 md:mt-0 flex flex-col justify-between">
                      <div>
                        <h3 className="text-xl font-semibold text-gray-700">
                          {item.name}
                        </h3>
                        <p className="text-gray-500 font-semibold my-1">
                          Price:{' '}
                          <span className="text-blue-700">
                            ${item.price}
                          </span>{' '}
                          {item.oldPrice && (
                            <span className="line-through">
                              ${item.oldPrice}
                            </span>
                          )}
                        </p>
                        <p className="text-gray-500 font-semibold">
                          License:{' '}
                          <span className="text-blue-600">
                            {item.license}
                          </span>
                        </p>
                        <p className="text-gray-500 font-semibold">
                          Version:{' '}
                          <span className="text-gray-400">
                            {item.version}.0
                          </span>
                        </p>
                        <p className="text-gray-500 font-semibold">
                          Technology:{' '}
                          <span className="text-gray-600">
                            {item.title}
                          </span>
                        </p>
                        <p className="text-gray-500 font-semibold">
                          Quantity:{' '}
                          <span className="text-gray-600">
                            {item.quantity}
                          </span>
                        </p>

                        {/* Quantity Display */}
                        {/* <div className="flex items-center mt-4">
                          <button
                            onClick={() => decreaseQuantity(item)}
                            className="px-3 py-1 bg-gray-300 text-gray-800 rounded-l-full hover:bg-gray-400 transition-colors duration-200"
                          >
                            -
                          </button>
                          <span className="px-4 py-1 bg-gray-200 text-gray-800 font-semibold">
                            {item.quantity}
                          </span>
                          <button
                            onClick={() => increaseQuantity(item)}
                            className="px-3 py-1 bg-gray-300 text-gray-800 rounded-r-full hover:bg-gray-400 transition-colors duration-200"
                          >
                            +
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* Remove Item Button */}
                  <div className="flex items-center justify-end mt-4 md:mt-0 md:ml-auto">
                    <button
                      onClick={() => removeFromCart(item.uniqueId)}
                      className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-red-500 to-red-600 text-white hover:text-slate-100 hover:from-red-600 hover:to-red-700 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600 transition-all duration-200"
                    >
                      <FaRegTrashAlt />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-span-1 md:col-span-4 mt-6 md:mt-0">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-6">
                  Order Summary
                </h3>
                <div className="flex justify-between mb-4">
                  <p className="text-gray-500 font-semibold">Price</p>
                  <p className="text-gray-700 font-semibold">
                    ${totalAmount.toFixed(2)}
                  </p>
                </div>
                <div className="flex justify-between mb-4">
                  <p className="text-gray-500 font-semibold">
                    Handling Charges
                  </p>
                  <p className="text-green-500 font-semibold">Free</p>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between mb-6">
                  <p className="text-gray-800 font-bold text-lg">
                    Total
                  </p>
                  <p className="text-gray-800 font-bold text-lg">
                    ${totalAmount.toFixed(2)}
                  </p>
                </div>
                <button
                  onClick={checkOut}
                  className="w-full py-3 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 text-white font-semibold hover:from-blue-700 hover:to-purple-700 transition-all duration-200"
                >
                  Checkout
                </button>

                <div className="my-6">
                  <h4 className="text-md font-semibold mb-2">
                    Apply Coupon Code
                  </h4>
                  <p className="text-gray-500 mb-4">
                    Have a coupon code? Apply it here!
                  </p>
                  <div className="flex">
                    <input
                      type="text"
                      value={codeInput}
                      onChange={(e) => setCodeInput(e.target.value)}
                      className="flex-1 px-4 py-2 border rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-600"
                      placeholder="Enter code"
                    />
                    <button
                      onClick={() => setCodeError(true)}
                      className="bg-blue-600 text-white px-4 py-2 rounded-r-full hover:bg-blue-700 transition-colors duration-200"
                    >
                      Apply
                    </button>
                  </div>
                  {codeError && (
                    <small className="text-red-500 mt-2 inline-block font-semibold">
                      This code is not valid!
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center text-gray-700 my-20 font-semibold">
            <p className="text-3xl font-bold mb-4">Your cart is empty!</p>
            <p className="text-lg mb-8">
              Browse our categories and discover our best deals!
            </p>
            <Link
              to="/"
              className="inline-flex items-center justify-center rounded-full py-3 px-6 text-md font-semibold bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700 transition-all duration-200"
            >
              Buy Projects
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartPage;
