import React from 'react';
import { LightBulbIcon, ChartBarIcon, UserCircleIcon, BeakerIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const ServiceSection = () => {
  return (
    <section className="relative overflow-hidden bg-white py-16">
      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-32 h-32 bg-blue-300 rounded-full blur-3xl opacity-20"></div>
      <div className="absolute bottom-0 right-0 w-48 h-48 bg-purple-300 rounded-full blur-3xl opacity-20"></div>
      
      <div className="container mx-auto px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Image Section */}
          <img src="images/service-1.jpg" alt="Generative AI Solutions" className="w-full rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl" />

          {/* Content Section */}
          <div className="lg:pl-8">
            <div className="max-w-lg">
              <h3 className="inline-block px-3 py-1 text-sm tracking-wider text-blue-600 font-bold uppercase rounded-full bg-blue-50">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Transform Your Business</span>
              </h3>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Generative AI Solutions
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-700">
                Unlock the potential of generative AI to enhance creativity, streamline workflows, and drive innovation across your business operations.
              </p>
              
              {/* Feature List */}
              <dl className="mt-10 space-y-8 text-base leading-7 text-gray-600">
                <div className="relative pl-10">
                  <dt className="inline font-semibold text-gray-900">
                    <LightBulbIcon className="absolute left-1 top-1 h-6 w-6 text-blue-600" />
                    Creative Content Generation
                  </dt>
                  <dd className="inline ml-1">Generate high-quality content, from text to images, with our state-of-the-art AI models.</dd>
                </div>
                <div className="relative pl-10">
                  <dt className="inline font-semibold text-gray-900">
                    <ChartBarIcon className="absolute left-1 top-1 h-6 w-6 text-blue-600" />
                    Advanced Data Analysis
                  </dt>
                  <dd className="inline ml-1">Utilize generative AI to analyze complex datasets and uncover actionable insights.</dd>
                </div>
                <div className="relative pl-10">
                  <dt className="inline font-semibold text-gray-900">
                    <UserCircleIcon className="absolute left-1 top-1 h-6 w-6 text-blue-600" />
                    Personalized Customer Experiences
                  </dt>
                  <dd className="inline ml-1">Deliver highly personalized experiences to your customers using AI-driven insights.</dd>
                </div>
                <div className="relative pl-10">
                  <dt className="inline font-semibold text-gray-900">
                    <BeakerIcon className="absolute left-1 top-1 h-6 w-6 text-blue-600" />
                    Innovative AI Research
                  </dt>
                  <dd className="inline ml-1">We continuously innovate and push the boundaries of what AI can achieve.</dd>
                </div>
              </dl>
            </div>

            {/* CTA Buttons */}
            <div className="mt-10 flex items-center gap-x-6">
              <Link to="/contact" className="rounded-full px-5 py-3 text-sm font-semibold text-white shadow-md bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:text-slate-100 hover:from-blue-700 hover:to-purple-700 active:bg-blue-800 transition">
                Connect with Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
