import React from 'react';
import { Link } from 'react-router-dom';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container mx-auto py-12 px-4 md:px-12 lg:px-24">
        <div className="flex flex-wrap justify-between">
          {/* Column 1: Logo and Description */}
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <Link to="/" className="flex items-center space-x-3 mb-4">
              <img src="/images/logo.png" className="h-12" alt="GenAI Box Logo" />
              <span className="text-2xl font-extrabold text-white">GenAI Box</span>
            </Link>
            <p className="text-gray-400 text-base leading-relaxed">
              GenAI Box is your go-to solution for cutting-edge generative AI projects. From automating tasks to enhancing your business workflows, our AI-powered tools bring innovation to your fingertips.
            </p>
          </div>

          {/* Column 2: Company */}
          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h2 className="text-lg font-semibold text-white mb-4">Company</h2>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-blue-400 transition-colors duration-300">About Us</Link></li>
              <li><Link to="/terms-and-conditions" className="hover:text-blue-400 transition-colors duration-300">Terms &amp; Conditions</Link></li>
              <li><Link to="/privacy-policy" className="hover:text-blue-400 transition-colors duration-300">Privacy Policy</Link></li>
              <li><Link to="/contact" className="hover:text-blue-400 transition-colors duration-300">Contact Us</Link></li>
            </ul>
          </div>

          {/* Column 3: Solutions */}
          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h2 className="text-lg font-semibold text-white mb-4">Solutions</h2>
            <ul className="space-y-2">
              <li><a target="_empty" href="https://documentai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300">Document Extraction</a></li>
              <li><a target="_empty" href="https://interviewai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300">Interview Preparation</a></li>
              <li><a target="_empty" href="https://hrai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300">HR Management</a></li>
              <li><a target="_empty" href="https://connectai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300">Content Creation</a></li>
            </ul>
          </div>

          {/* Column 4: Resources */}
          <div className="w-full md:w-1/5">
            <h2 className="text-lg font-semibold text-white mb-4">Image Solutions</h2>
            <ul className="space-y-2">
              <li><a target="_empty" href="https://clothchangerai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300">Outfit Changer</a></li>
              <li><a target="_empty" href="https://imageenhancerai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300"> Enhancer</a></li>
              <li><a target="_empty" href="https://imagegenerator.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300"> Generator</a></li>
              <li><a target="_empty" href="https://picturifyai.genaibox.io/" className="hover:text-blue-400 transition-colors duration-300"> Inpainting</a></li>
            </ul>
          </div>
        </div>

        <div className="mt-12 border-t border-gray-800 pt-6 text-center">
          <p className="text-sm text-gray-500">
            © {currentYear} GenAI Box. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
