import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';  // Import uuid for unique IDs
import { encryptData, decryptData } from '../utils/encryption';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  useEffect(() => {
    const storedCart = localStorage.getItem('R#W42FG*FG');
    if (storedCart) {
      const decryptedCart = decryptData(storedCart);
      if (decryptedCart) {
        setCart(decryptedCart);
      } else {
        console.error('Failed to decrypt cart data');
      }
    }
    setIsCartLoaded(true); // Mark cart as loaded
  }, []);

  useEffect(() => {
    if (isCartLoaded) {
      const encryptedCart = encryptData(cart);
      if (encryptedCart) {
        localStorage.setItem('R#W42FG*FG', encryptedCart);
      } else {
        console.error('Failed to encrypt cart data');
      }
    }
  }, [cart, isCartLoaded]);

  const addToCart = (newItem) => {
    setCart((prevCart) => {
      // Check if the item already exists in the cart by content_id (or other properties like id, title, version)
      const existingItemIndex = prevCart.findIndex(
        (item) => item.content_id === newItem.content_id && item.version_id === newItem.version_id
      );

      if (existingItemIndex !== -1) {
        // If item exists, increase its quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].quantity += 1;
        return updatedCart;
      } else {
        // If item doesn't exist, add it as a new entry
        return [...prevCart, { ...newItem, quantity: 1, uniqueId: uuidv4() }]; // Assign a unique ID and set quantity to 1
      }
    });
  };

  const removeFromCart = (uniqueId) => {
    setCart((prevCart) => prevCart.filter(item => item.uniqueId !== uniqueId)); // Remove only the item with the matching uniqueId
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
