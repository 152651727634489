import React from 'react';
import { FaLightbulb, FaGlobe } from 'react-icons/fa';

const MissionVisionSection = () => {
  return (
    <section className="relative pb-16 bg-gray-50">
 
      <div className="container mx-auto px-10">
        <div className="grid gap-12 lg:grid-cols-2 lg:items-center">
          
          {/* Mission */}
          <div className="p-6 bg-white shadow-lg rounded-xl hover:shadow-xl transition-shadow">
            <div className="flex items-center mb-4">
              <FaLightbulb className="text-blue-600 w-6 h-6 mr-3" />
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900">Our Mission</h2>
            </div>
            <p className="text-md text-gray-700 leading-relaxed">
              At <span className="font-semibold text-blue-600">GenAI Box</span>, our mission is to empower businesses with cutting-edge generative AI solutions that 
              drive innovation, improve efficiency, and accelerate growth. We believe in leveraging the power of AI to simplify complex workflows, enabling businesses 
              to focus on strategic decisions and creative problem-solving.
            </p>
          </div>

          {/* Vision */}
          <div className="p-6 bg-white shadow-lg rounded-xl hover:shadow-xl transition-shadow">
            <div className="flex items-center mb-4">
              <FaGlobe className="text-blue-600 w-6 h-6 mr-3" />
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900">Our Vision</h2>
            </div>
            <p className="text-md text-gray-700 leading-relaxed">
              Our vision is to become a global leader in generative AI, transforming industries and revolutionizing business operations through intelligent automation.
              We aspire to shape the future by creating AI-driven solutions that push the boundaries of what's possible, setting a new standard for innovation and excellence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVisionSection;
