import React from 'react';

const CallToAction = () => {
  return (
    <section className="relative py-20 bg-gradient-to-r from-blue-100 via-white to-blue-100 overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-40 h-40 bg-blue-200 rounded-full blur-2xl opacity-30"></div>
      <div className="absolute bottom-0 right-0 w-60 h-60 bg-blue-400 rounded-full blur-3xl opacity-30"></div>
      
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
        {/* Section Heading */}
        <h2 className="text-4xl font-extrabold text-gray-900 mb-4 md:text-5xl">
          Got Questions? <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">We're Here to Help</span>
        </h2>
        <p className="text-lg text-gray-600 mb-8 max-w-xl mx-auto">
          Whether you're curious about our AI solutions or need help with implementation, our team is ready to assist. Reach out today and let's make great things happen together.
        </p>
        
        {/* Call to Action Button */}
        <a
          href="/contact"
          className="inline-block bg-gradient-to-r from-blue-600 to-purple-600 text-white font-semibold py-4 px-12 rounded-full shadow-lg hover:shadow-xl hover:from-blue-700 hover:to-purple-700 transform transition duration-300 ease-in-out hover:scale-105"
        >
          Contact Us
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
