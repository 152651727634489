import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaStar, FaCrown } from 'react-icons/fa';

const HomeProject = () => {
  // Dummy project data for Premium and Premium Pro sections
  const navigate = useNavigate();

  const premiumProjects = [
    {
      id: 4,
      name: "Transform Images",
      slug: "picturify_transform_images",
      description: "Convert and enhance images with AI. Perfect for marketing and social media.",
      code: "WordPress, ReactJS, AngularJS, VueJS, HTML",
      image: "images/projects/picturify-banner.jpg",
      cost: 1,
      url: "https://picturifyai.genaibox.io/"
    },
    {
      id: 5,
      name: "Image Generator",
      slug: "imagegenai_image_generator",
      description: "Create high-quality images from text prompts for design and content.",
      code: "WordPress, ReactJS, AngularJS, VueJS, HTML",
      image: "images/projects/image-generator-ai.jpg",
      cost: 1,
      url: "https://imagegenerator.genaibox.io/"
    },
    {
      id: 6,
      name: "Outfit Changer",
      slug: "clothchangerai_outfit_changer",
      description: "Virtually change outfits in images. Ideal for fashion retailers.",
      code: "WordPress, ReactJS, AngularJS, VueJS, HTML",
      image: "images/projects/cloth-changer-ai.jpg",
      cost: 1,
      url: "https://clothchangerai.genaibox.io/"
    },
    {
      id: 7,
      name: "Image Enhancer",
      slug: "imageenhancerai_image_inpaint",
      description: "Enhance image quality with AI tools to improve clarity and reduce noise.",
      code: "WordPress, ReactJS, AngularJS, VueJS, HTML",
      image: "images/projects/image-enhancer-ai.jpg",
      cost: 1,
      url: "https://imageenhancerai.genaibox.io/"
    },
  ];
  

  const premiumProProjects = [
    {
      id: 1,
      name: "Data Extractor",
      slug: "documentai_data_extractor",
      description: "Automate data extraction from multiple type of documents and images.",
      code: "Django, Spring Boot, ExpressJS, Laravel, ReactJS",
      image: "images/projects/documentscan-ai.jpg",
      cost: 30,
      url: "https://documentai.genaibox.io/"
    },
    {
      id: 3,
      name: "Content Creator",
      slug: "connectai_content_creator",
      description: "Generate SEO-friendly content for blogs and marketing effortlessly.",
      code: "Django, Spring Boot, ExpressJS, Laravel, ReactJS",
      image: "images/projects/connect-ai.jpg",
      cost: 25,
      url: "https://connectai.genaibox.io/"
    },
    {
      id: 2,
      name: "HR Management",
      slug: "hrai_interview_management",
      description: "Streamline HR tasks from recruitment to employee management.",
      code: "Django, Spring Boot, ExpressJS, Laravel, ReactJS",
      image: "images/projects/hr-ai.jpg",
      cost: 20,
      url: "https://hrai.genaibox.io/"
    },
    {
      id: 8,
      name: "Interview Preparation",
      slug: "interviewai_interview_preparation",
      description: "Prepare candidates with AI-based simulations and assessments.",
      code: "Django, ExpressJS, Laravel, ReactJS",
      image: "images/projects/interview-ai.jpg",
      cost: 20,
      url: "https://interviewai.genaibox.io/"
    },
  ];  

  const handleProject = (id, slug) => {
    navigate(`/project/${slug}`, { state: { id: id } });
  }

  return (
    <div id="projects" className="container mx-auto px-4 py-16 sm:px-6 lg:px-8 lg:py-24"> 

      {/* Premium Projects Section */}
      <div className="mb-16">
        <div className="flex items-left justify-start mb-2">
          <h3 className="text-3xl md:text-4xl font-bold text-gray-900"><span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Premium</span> AI Solutions</h3>
        </div>
        <p className="text-left text-lg text-gray-600 mb-8">
          Unlock our affordable $1 AI solutions designed to boost your business efficiency.
          {/* Unlock our affordable AI solutions designed to boost your business efficiency. */}
        </p>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {premiumProjects.map((project) => (
            <div
              key={project.id}
              className="relative group bg-white rounded-xl overflow-hidden shadow-lg transform transition duration-300 hover:shadow-2xl hover:-translate-y-2"
            >
              <button onClick={() => handleProject(project.id, project.slug)}>
                <div className="relative">
                  <img
                    className="object-cover w-full"
                    src={project.image}
                    alt={project.name}
                  />
                  <div className="absolute top-2 left-2 bg-blue-500 text-white text-xs font-bold py-1 px-2 rounded-full">
                    Premium
                  </div>
                  <div className="absolute top-4 right-4 bg-gray-800 bg-opacity-75 text-white text-xs font-bold py-1 px-2 rounded-full">
                    ${project.cost}
                  </div>
                </div>
                <div className="p-4 text-left bg-blue-50">
                  <h3 className="mb-2 text-xl font-bold text-gray-900 group-hover:text-blue-600">
                    {project.name}
                  </h3>
                  <p className="text-sm text-gray-600">{project.description}</p>
                  <p className="text-sm text-gray-600 mt-2 font-semibold"><span className='text-blue-600'>Available in: </span>{project.code}</p>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Premium Pro Projects Section */}
      <div>
        <div className="flex items-left justify-start mb-2">
          <h3 className="text-3xl md:text-4xl font-bold text-gray-900"><span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Premium Pro</span> AI Solutions</h3>
        </div>
        <p className="text-left text-lg text-gray-600 mb-8">
          Explore our top-tier AI solutions with advanced features to supercharge your business.
        </p>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {premiumProProjects.map((project) => (
            <div
              key={project.id}
              className="relative group bg-purple-50 rounded-xl overflow-hidden shadow-lg transform transition duration-300 hover:shadow-2xl hover:-translate-y-2"
            >
              <button onClick={() => handleProject(project.id, project.slug)}>
                <div className="relative">
                  <img
                    className="object-cover w-full"
                    src={project.image}
                    alt={project.name}
                  />
                  <div className="absolute top-2 left-2 bg-purple-600 text-white text-xs font-bold py-1 px-3 rounded-full">
                    Pro
                  </div>
                  <div className="absolute top-4 right-4 bg-gray-800 bg-opacity-75 text-white text-xs font-bold py-1 px-2 rounded-full">
                    ${project.cost}
                  </div>
                </div>
                <div className="p-4 text-left bg-purple-50">
                  <h3 className="mb-2 text-xl font-bold text-gray-900 group-hover:text-purple-600">
                    {project.name}
                  </h3>
                  <p className="text-sm text-gray-600">{project.description}</p>
                  <p className="text-sm text-gray-600 mt-2 font-semibold"><span className='text-blue-600'>Available in: </span>{project.code}</p>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeProject;
