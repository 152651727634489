import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LiaTimesSolid } from "react-icons/lia";
import { IoCartOutline } from "react-icons/io5";
import { CartContext } from '../../Common/CartContext';
import { useAuth } from '../../Common/AuthProvider';
import "../../style/scrollview.css"

const Navbar = () => {
  const [isCartDropdownOpen, setCartDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { cart } = useContext(CartContext);
  const { logout } = useAuth();
  const cartDropdownRef = useRef(null); // To track clicks outside the cart dropdown
  const location = useLocation();
  const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'text-blue-600' : 'text-slate-700';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleCartDropdown = () => {
    setCartDropdownOpen((prevState) => !prevState);
  };

  // Click outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cartDropdownRef.current &&
        !cartDropdownRef.current.contains(event.target)
      ) {
        setCartDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const userActive = localStorage.getItem('S%$FO*$#S7');

  return (
    <header className="py-3 relative z-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative z-50 flex justify-between items-center">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" className="flex items-center space-x-3">
              <img src="/images/logo.png" className="h-8 md:h-12" alt="Logo" />
              <span className="self-center text-2xl font-extrabold whitespace-nowrap">
                GenAI Box
              </span>
            </Link>
          </div>

          <div className="flex-1 flex justify-center">
            <div className="hidden md:flex md:gap-x-6">
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/')} hover:bg-slate-100 hover:text-slate-900`} to="/">Home</Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/about')} hover:bg-slate-100 hover:text-slate-900`} to="/about">Company</Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/solution')} hover:bg-slate-100 hover:text-slate-900`} to="/solution">Solutions</Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/blogs')} hover:bg-slate-100 hover:text-slate-900`} to="/blogs">Updates</Link>
              <Link className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/contact')} hover:bg-slate-100 hover:text-slate-900`} to="/contact">Support</Link>
            </div>
          </div>

          <div className="flex items-center gap-x-5 md:gap-x-8">
            {totalItems > 0 && (
              <div className="relative" ref={cartDropdownRef}>
                <button className="relative" onClick={toggleCartDropdown}>
                  <IoCartOutline className="text-2xl text-slate-700 hover:text-blue-600" />
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-blue-600 text-white text-xs font-semibold rounded-full h-5 w-5 flex items-center justify-center">
                    {totalItems}
                  </span>
                </button>


                {isCartDropdownOpen && (
                  <div className="absolute right-0 mt-3 md:mt-2 w-72 md:w-96 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-50">
                    <h4 className="text-gray-700 font-bold mb-2">Cart Preview</h4>
                    <ul className="max-h-56 px-4 overflow-auto custom-scrollbar">
                      {cart.length > 0 ? (
                        cart.map((item) => (
                          <li key={item.uniqueId} className="flex justify-between items-center mb-4">
                            <span className="font-semibold text-sm pr-8">{item.name} {item.title} Qty: {item.quantity}</span>
                            <span className="text-blue-600">${item.price}</span>
                          </li>
                        ))
                      ) : (
                        <p className="text-gray-500 text-sm">Your cart is empty.</p>
                      )}
                    </ul>
                    <Link
                      to="/cart"
                      className="block w-40 mx-auto rounded-full py-1 font-semibold text-center mt-6 text-blue-600 border bg-white border-blue-600 hover:from-blue-50 hover:to-blue-50 hover:bg-blue-50"
                      onClick={() => setCartDropdownOpen(false)} // Close dropdown on cart page view
                    >
                      View Cart
                    </Link>
                  </div>
                )}
              </div>
            )}

            {!userActive ? (
              <>
                <div className="hidden md:block">
                  <Link className={`inline-block font-semibold rounded-lg px-2 py-1 ${activeLink('/signin')} text-md hover:bg-slate-100 hover:text-slate-900`} to="/signin">Sign in</Link>
                </div>
                <Link className="hidden md:inline group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-purple-800 active:text-blue-100 focus-visible:outline-blue-600" to="/signup">
                  <span>Get started <span className="hidden lg:inline">today</span></span>
                </Link>
              </>
            ) : (
              <div className="relative">
                <button
                  id="dropdownHoverButton"
                  onClick={toggleDropdown}
                  className="group hidden md:inline-flex items-center justify-center rounded-full py-2 -mr-4 px-3 md:px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:text-slate-100 hover:from-blue-700 hover:to-purple-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"
                  type="button"
                >
                  Dashboard
                  <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                  </svg>
                </button>

                {isDropdownOpen && (
                  <div
                    id="dropdownHover"
                    className="absolute hidden md:flex right-0 z-10 mt-2 bg-white rounded-lg shadow-lg w-56 dark:bg-gray-800"
                  >
                    <ul className="py-2 w-full m-2 text-sm text-gray-700 dark:text-gray-200">
                      <li>
                        <Link
                          to="/download"
                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-blue-100 to-blue-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <span>Download</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/support"
                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-yellow-100 to-yellow-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <span>Project Support</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/settings"
                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-green-100 to-green-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <span>Settings</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="block px-4 py-3 text-gray-900 dark:text-gray-300 hover:bg-gradient-to-r from-red-100 to-red-200 dark:hover:bg-gray-600 dark:hover:text-white rounded-lg transition duration-200 ease-in-out"
                          onClick={() => { setIsDropdownOpen(false); logout(); }}
                        >
                          <div className="flex items-center space-x-2 font-semibold text-gray-500">
                            <span>Sign out</span>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}

            <div className="-mr-1 md:hidden">
              <button onClick={toggleMobileMenu} className="relative z-10 flex h-8 w-8 items-center justify-center">
                <svg aria-hidden="true" className="h-3.5 w-3.5 overflow-visible stroke-slate-700" fill="none" strokeWidth="2" strokeLinecap="round">
                  <path d={isMobileMenuOpen ? "" : "M0 1H14M0 7H14M0 13H14"} className="origin-center transition" />
                </svg>
              </button>
            </div>
          </div>
        </nav>

        {isMobileMenuOpen && (
          <div className="fixed inset-0 z-50 flex items-left justify-left bg-gray-800 bg-opacity-75 transition-opacity">
            <div id="mobileMenu" className="bg-white p-6 shadow-lg w-3/4">
              <button onClick={toggleMobileMenu} className="absolute top-4 right-4 text-white hover:text-gray-700">
                <LiaTimesSolid className="w-5 h-5" />
              </button>
              <Link to="/" className="flex items-center space-x-3 mb-4 rtl:space-x-reverse">
                <img src="/images/logo.png" className="h-12" alt="Logo" />
                <span className="self-center text-2xl font-extrabold whitespace-nowrap">
                  GenAI Box
                </span>
              </Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/" onClick={toggleMobileMenu}>Home</Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/about" onClick={toggleMobileMenu}>Company</Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/solution" onClick={toggleMobileMenu}>Solutions</Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/blog" onClick={toggleMobileMenu}>Updates</Link>
              <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/contact" onClick={toggleMobileMenu}>Support</Link>

              {!userActive ? (
                <>
                  <Link className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" to="/signin" onClick={toggleMobileMenu}>Sign in</Link>
                  <Link className="md:inline group inline-flex items-center justify-center rounded-full mt-2 py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:text-slate-100 hover:from-blue-600 hover:to-blue-700 active:bg-purple-800 active:text-blue-100 focus-visible:outline-blue-600" to="/signup" onClick={toggleMobileMenu}>
                    <span>Get started today</span>
                  </Link>
                </>
              ) : (
                <div className="bg-blue-50 p-4 pt-2 rounded-xl pl-2">
                  <Link to="/download" className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" onClick={toggleMobileMenu}>Download</Link>
                  <Link to="/support" className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" onClick={toggleMobileMenu}>Project Support</Link>
                  <Link to="/settings" className="block font-semibold rounded-lg px-2 py-2 text-md text-slate-700 hover:bg-slate-100 hover:text-slate-900" onClick={toggleMobileMenu}>Settings</Link>
                  <Link to="/signout" className="block font-semibold rounded-full px-8 w-32 mt-2   px-2 py-2 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:text-slate-100 hover:from-blue-700 hover:to-purple-700 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">Sign out</Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
