import React from 'react';
import { SparklesIcon, ChartBarIcon, PencilIcon, MicrophoneIcon, ShieldCheckIcon, UserGroupIcon, ArrowRightIcon } from '@heroicons/react/24/outline';


const GenAIUseCases = () => {
  const useCases = [
    {
      title: "Automated Content Creation",
      description:
        "Leverage AI to create high-quality content for blogs, marketing materials, and product descriptions. GenAIBox generates content in seconds, tailored to your business needs.",
      icon: <SparklesIcon className="w-10 h-10 text-blue-500" />,
      gradient: "from-blue-200 to-purple-200",
      benefits: ["Faster production", "Cost-effective", "High-quality content"],
      link: "/use-cases/automated-content",
    },
    {
      title: "Real-Time Data Analytics",
      description:
        "Analyze data streams in real time, providing actionable insights to optimize business processes and decision-making. GenAIBox excels in pattern recognition and data prediction.",
      icon: <ChartBarIcon className="w-10 h-10 text-blue-500" />,
      gradient: "from-blue-200 to-purple-200",
      benefits: ["Real-time insights", "Predictive analysis", "Business optimization"],
      link: "/use-cases/data-analytics",
    },
    {
      title: "Creative Design Enhancement",
      description:
        "Enhance your design process with AI-driven suggestions for layouts, logos, and creative elements. GenAIBox provides creative professionals with AI-powered inspiration.",
      icon: <PencilIcon className="w-10 h-10 text-blue-500" />,
      gradient: "from-blue-200 to-purple-200",
      benefits: ["Enhanced creativity", "AI-powered suggestions", "Faster design process"],
      link: "/use-cases/creative-design",
    },
    // {
    //   title: "Speech and Text Synthesis",
    //   description:
    //     "With AI-driven speech synthesis, GenAIBox enables realistic, human-like voices for customer interactions, voice assistants, and text-to-speech applications.",
    //   icon: <MicrophoneIcon className="w-10 h-10 text-blue-500" />,
    //   gradient: "from-blue-200 to-purple-200",
    //   benefits: ["Natural speech", "Improved accessibility", "Voice-enabled solutions"],
    //   link: "/use-cases/speech-synthesis",
    // },
    // {
    //   title: "Fraud Detection and Risk Management",
    //   description:
    //     "Identify and mitigate fraud with GenAIBox’s advanced AI capabilities. Detect anomalies and manage risk in real-time for enhanced security and compliance.",
    //   icon: <ShieldCheckIcon className="w-10 h-10 text-blue-500" />,
    //   gradient: "from-blue-200 to-purple-200",
    //   benefits: ["Real-time fraud detection", "Risk management", "Increased security"],
    //   link: "/use-cases/fraud-detection",
    // },
    // {
    //   title: "Customer Experience Automation",
    //   description:
    //     "AI-powered chatbots and virtual assistants enable seamless customer interactions, increasing efficiency and improving user satisfaction.",
    //   icon: <UserGroupIcon className="w-10 h-10 text-blue-500" />,
    //   gradient: "from-blue-200 to-purple-200",
    //   benefits: ["24/7 support", "Personalized interactions", "Efficiency boost"],
    //   link: "/use-cases/customer-experience",
    // },
  ];

  return (
    <section className="relative py-16 bg-gradient-to-b from-white to-gray-50 overflow-hidden">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-5xl font-extrabold text-gray-900 tracking-tight">
            Discover GenAIBox <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Solutions</span>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
            Unleash the full potential of Generative AI with GenAIBox. Explore our advanced AI use cases that empower businesses to innovate and optimize operations.
          </p>
        </div>

        {/* Use Case Grid */}
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {useCases.map((useCase, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-lg transform transition-transform hover:-translate-y-3 hover:shadow-2xl border-t-4 group"
            >
              <div className="flex items-center space-x-4 mb-4">
                <div className={`p-4 rounded-full bg-gradient-to-r ${useCase.gradient}`}>
                  {useCase.icon}
                </div>
                <h3 className="text-xl md:text-2xl font-bold text-gray-800 group-hover:text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 transition">
                  {useCase.title}
                </h3>
              </div>
              <p className="text-gray-600 mb-4">{useCase.description}</p>
              <ul className="text-sm text-gray-500 mb-6 space-y-1">
                {useCase.benefits.map((benefit, idx) => (
                  <li key={idx} className="flex items-center">
                    <span className="w-2 h-2 bg-blue-500 rounded-full inline-block mr-2"></span>
                    {benefit}
                  </li>
                ))}
              </ul> 
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GenAIUseCases;
