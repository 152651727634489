import React, { useEffect } from 'react';
import { FaAngleDoubleRight, FaRocket } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HomePageHero = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <div className="relative bg-gradient-to-b from-blue-50 via-white to-white mx-auto max-w-full px-4 sm:px-6 lg:px-8 pt-16 pb-4 md:py-24 text-center overflow-hidden">
      {/* Decorative Background Elements */}
      <div className="absolute inset-0">
        <svg
          className="absolute inset-0 w-full h-full animate-wavy-slow"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#f0f9ff"
            fillOpacity="1"
            d="M0,288L40,266.7C80,245,160,203,240,197.3C320,192,400,224,480,218.7C560,213,640,171,720,165.3C800,160,880,192,960,181.3C1040,171,1120,117,1200,106.7C1280,96,1360,128,1400,144L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          ></path>
        </svg>
      </div>

      <div className="relative z-10">
        {/* Heading */}
        <h1
          className="mx-auto max-w-4xl text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight text-slate-900"
          data-aos="fade-down"
        >
          Unlock the Power of{' '}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
            Generative AI
          </span>
        </h1>

        {/* Subheading */}
        <p
          className="mx-auto mt-4 max-w-2xl text-base sm:text-lg lg:text-xl text-slate-700 leading-relaxed tracking-wide"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Empower your business with cutting-edge AI solutions for image generation, content creation, and more—all starting at just one click.
        </p>

        {/* Buttons */}
        <div
          className="mt-8 flex flex-col sm:flex-row justify-center gap-4 sm:gap-6"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <a
            className="group inline-flex items-center justify-center rounded-full py-4 px-8 text-sm sm:text-base font-semibold bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-blue-600 hover:to-purple-700 shadow-lg transform transition-transform hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            href="#projects"
          >
            Get Started Now
          </a>
          <Link
            className="group inline-flex items-center justify-center rounded-full py-4 px-8 text-sm sm:text-base font-semibold text-blue-600 bg-white border border-blue-600 hover:bg-blue-50 hover:text-blue-700 shadow-md transform transition-transform hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            to="/contact"
          >
            <FaAngleDoubleRight className="mr-2 group-hover:text-blue-700" /> Connect with Us
          </Link>
        </div>

        {/* Animated Success Modal */}
        {/* Optional: You can trigger this modal based on certain actions */}
        {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Lottie animationData={successAnimation} className="w-24 h-24 mx-auto" />
          <h2 className="text-2xl font-semibold mb-4">Success!</h2>
          <p className="text-gray-700">Your action was successful.</p>
        </Modal> */}

        {/* Testimonials */}
        <div className="relative z-10 mt-12 grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-4xl mx-auto">
          {/* Testimonial 1 */}
          <div
            className="bg-white backdrop-filter backdrop-blur-md bg-opacity-30 border border-white rounded-lg shadow-lg p-6 flex items-start transition-transform transform hover:scale-105 hover:shadow-2xl"
          >
            <div className="flex-shrink-0">
              <img
                src="https://randomuser.me/api/portraits/men/40.jpg"
                alt="John D. Content Manager"
                className="h-12 w-12 rounded-full border-2 border-blue-200 shadow-sm"
                loading="lazy"
              />
            </div>
            <div className="ml-4 text-left">
              <div className="text-xs text-blue-500 font-semibold">EXCELLENT TOOL</div>
              <div className="text-sm text-gray-700 mt-1">
                "GenAIBox transformed our content creation process. Highly recommended!"
              </div>
              <div className="text-xs text-gray-500 mt-2">— John D. Content Manager</div>
            </div>
          </div>

          {/* Testimonial 2 */}
          <div
            className="bg-white backdrop-filter backdrop-blur-md bg-opacity-30 border border-white rounded-lg shadow-lg p-6 flex items-start transition-transform transform hover:scale-105 hover:shadow-2xl"
          >
            <div className="flex-shrink-0">
              <img
                src="https://randomuser.me/api/portraits/women/64.jpg"
                alt="Sarah L. Marketing Director"
                className="h-12 w-12 rounded-full border-2 border-purple-200 shadow-sm"
                loading="lazy"
              />
            </div>
            <div className="ml-4 text-left">
              <div className="text-xs text-purple-500 font-semibold">GAME CHANGER</div>
              <div className="text-sm text-gray-700 mt-1">
                "The AI image generator saved us hours of design work."
              </div>
              <div className="text-xs text-gray-500 mt-2">— Sarah L. Marketing Director</div>
            </div>
          </div>
        </div>

        {/* Partner Logos */}
        <div
          className="mx-8 mt-16 flex flex-wrap justify-center items-center gap-8"
        >
          <img
            src="/images/open-ai.png"
            alt="OpenAI"
            className="h-6 md:h-12 opacity-80 hover:opacity-100 transition-opacity duration-300"
            loading="lazy"
          />
          <img
            src="/images/Stability-AI.png"
            alt="Stability AI"
            className="h-6 md:h-12 opacity-80 hover:opacity-100 transition-opacity duration-300"
            loading="lazy"
          />
          <img
            src="/images/Llama.png"
            alt="Llama"
            className="h-4 md:h-8 opacity-80 hover:opacity-100 transition-opacity duration-300"
            loading="lazy"
          />
          <img
            src="/images/gemini.png"
            alt="Gemini"
            className="h-5 md:h-10 opacity-80 md:-mt-2 md:-ml-2 hover:opacity-100 transition-opacity duration-300"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePageHero;
