import React from 'react';
import { FaHandsHelping, FaChartLine, FaLightbulb, FaShieldAlt } from 'react-icons/fa';

const CoreValuesSection = () => {
  const coreValues = [
    {
      title: 'Innovation',
      description: 'We foster creativity and embrace new ideas to stay ahead.',
      icon: <FaLightbulb className="text-white w-6 h-6" />,
      gradient: 'from-blue-500 to-purple-600',
    },
    {
      title: 'Integrity',
      description: 'We conduct our business with the highest ethical standards.',
      icon: <FaShieldAlt className="text-white w-6 h-6" />,
      gradient: 'from-blue-500 to-purple-600',
    },
    {
      title: 'Customer Focus',
      description: 'Our clients are at the heart of everything we do.',
      icon: <FaHandsHelping className="text-white w-6 h-6" />,
      gradient: 'from-blue-500 to-purple-600',
    },
    {
      title: 'Excellence',
      description: 'We strive for excellence in all aspects of our work.',
      icon: <FaChartLine className="text-white w-6 h-6" />,
      gradient: 'from-blue-500 to-purple-600',
    },
  ];

  return (
    <section className="pt-8 md:pt-20 pb-32 bg-gray-50 relative overflow-hidden">
      
      <div className="container mx-auto px-6 sm:px-12 lg:px-16 relative z-10">
        {/* Section Header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-4">
            Our Core Values
          </h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto">
            At GenAI Box, our core values define who we are and how we serve our clients. Discover the principles that drive our success and commitment to excellence.
          </p>
        </div>

        {/* Core Values Grid */}
        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
          {coreValues.map((value, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-8 flex flex-col items-center text-center transform hover:scale-105"
              
            >
              {/* Icon with Gradient Background */}
              <div
                className={`flex items-center justify-center w-12 h-12 mb-6 rounded-full bg-gradient-to-r ${value.gradient} shadow-lg`}
              >
                {value.icon}
              </div>
              {/* Title */}
              <h3 className="text-2xl font-bold text-gray-800 mb-3">
                {value.title}
              </h3>
              {/* Description */}
              <p className="text-gray-600 text-base leading-relaxed">
                {value.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CoreValuesSection;
