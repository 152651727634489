import React from 'react';
import CallToActionSection from './Home/Calltoactionsection';
import HomePageHero from './Home/HomePageHero';
import HomeProject from './Home/HomeProjects';
import HomeAboutUs from './Home/HomeAboutUs';
import GenAIUseCases from './Home/HomeUseCase';
import FAQSection from './Home/FAQSection';

const Home = () => {
    return (
        <div> 
            <HomePageHero />
            <HomeProject />
            <HomeAboutUs />
            <GenAIUseCases /> 
            <FAQSection />
            <CallToActionSection />
        </div>
    );
};

export default Home;
