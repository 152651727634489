import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Frontend/page/Home';
import About from '../Frontend/page/About';
import Services from '../Frontend/page/Service';
import Contact from '../Frontend/page/Contact';
import SigninPage from '../Frontend/page/Auth/Signin'; 
import ForgetPassword from '../Frontend/page/Auth/ForgetPassword'; 
import EmailVerification from '../Frontend/page/Auth/EmailVerification'; 
import PrivacyPolicy from '../Frontend/page/PrivacyPolicy';
import TermsAndConditions from '../Frontend/page/TermsAndConditions ';
import SignupPage from '../Frontend/page/Auth/Signup';
import CreateNewPassword from '../Frontend/page/Auth/CreateNewPassword';
import FrontendLayout from '../Layouts/FrontendLayout';
import ProtectedRoute from './ProtectedRoute'; 
import EmailVerified from '../Frontend/page/Auth/EmailVerified';
import Project from '../Frontend/page/Project';
import Profile from '../Frontend/page/User/Profile';
import CartPage from '../Frontend/page/Project/CartPage';
import CheckoutPage from '../Frontend/page/Project/Checkout';
import PaymentSuccess from '../Frontend/page/Project/PaymentSuccess';
import InvoAi from '../Frontend/page/InvoAI/InvoAI';
import InterviewAi from '../Frontend/page/InterviewAI/InterviewAI';
import DownloadTemplate from '../Frontend/page/User/DownloadTemplate';
import Document from '../Frontend/page/Document';
import ChatBox from '../Frontend/page/Support/ChatBox';
import ChatPage from '../Frontend/page/Support/ChatPage';
import Blogs from '../Frontend/page/Blog/Blogs';
import Blog from '../Frontend/page/Blog/Blog';
import BlogByCategory from '../Frontend/page/Blog/BlogByCategory';
import RegularLicense from '../Frontend/page/RegularLicense';
import DeleteAccountRequest from '../Frontend/page/Contact/DeleteAccount';
import DeleteAllDataRequest from '../Frontend/page/Contact/DeleteAllData';
import NFPrivacyPolicy from './NFPrivacyPolicy';
import TestModel from '../Frontend/page/TestModel';

const RouteList = () => {
  return (
    <Routes>
      <Route element={<FrontendLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/test-model" element={<TestModel />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/project/:slug" element={<Project />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/solution" element={<Services />} />
        <Route path="/invoai" element={<InvoAi />} />
        <Route path="/interviewai" element={<InterviewAi />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/delete-account" element={<DeleteAccountRequest />} />
        <Route path="/delete-all-data" element={<DeleteAllDataRequest />} />
        <Route path="/nf-privacy-policy" element={<NFPrivacyPolicy />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/category/:category" element={<BlogByCategory />} />
        <Route path="/blog/:slug" element={<Blog />} />
        <Route path="/download-template" element={<DownloadTemplate />} />
        <Route path="/document" element={<ProtectedRoute element={<Document />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="/settings" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="/support" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="/download" element={<ProtectedRoute element={<Profile />} />} />
        <Route path="/checkout" element={<ProtectedRoute element={<CheckoutPage />} />} />
        <Route path="/payment-success" element={<ProtectedRoute element={<PaymentSuccess />} />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/regular-license" element={<RegularLicense />} />
      </Route>
    </Routes>
  );
};

export default RouteList;
