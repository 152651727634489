import React, { useEffect } from 'react';
import { FaQuoteLeft, FaStar } from 'react-icons/fa';

const testimonials = [
  {
    name: "Daniella Rivera",
    role: "Mobile Developer at TechCorp",
    image: "https://randomuser.me/api/portraits/women/12.jpg",
    text: "GenAI Box has revolutionized our mobile development process. The AI-driven features save us countless hours each week and the accuracy is unmatched.",
    rating: 5,
  },
  {
    name: "Jane Smith",
    role: "Marketing Specialist at Creative Solutions",
    image: "https://randomuser.me/api/portraits/women/14.jpg",
    text: "Integrating GenAI Box with our existing systems was seamless. The support team was incredibly helpful and always available to assist us.",
    rating: 5,
  },
  {
    name: "Yanick Johnson",
    role: "Software Developer at CodeWorks",
    image: "https://randomuser.me/api/portraits/women/18.jpg",
    text: "The data extraction capabilities of GenAI Box are remarkable. It has drastically reduced errors and improved our overall workflow efficiency.",
    rating: 4,
  },
  {
    name: "Jane Doe",
    role: "Project Manager at InnovateX",
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    text: "GenAI Box's data visualization tools provide clear insights, helping us make informed decisions quickly and effectively.",
    rating: 5,
  },
  {
    name: "Andy Brown",
    role: "Operations Manager at LogisticsPro",
    image: "https://randomuser.me/api/portraits/men/62.jpg",
    text: "The reliability and uptime of GenAI Box's services are exceptional. We can trust that our operations will run smoothly at all times.",
    rating: 4,
  },
  {
    name: "Yanndy White",
    role: "Financial Analyst at MoneyMatters",
    image: "https://randomuser.me/api/portraits/men/19.jpg",
    text: "Using GenAI Box has streamlined our financial processes and improved accuracy. It's been a great decision for our team.",
    rating: 5,
  },
];

const TestimonialsSection = () => {

  return (
    <section className="pt-8 pb-20 md:py-20 bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-300">
      <div className="container mx-auto px-6 sm:px-12 lg:px-16">
        {/* Section Header */}
        <div className="text-center mb-12" >
          <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 dark:text-white">
            What Our Clients Say
          </h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-400">
            Trusted by professionals across various industries.
          </p>
        </div>
        {/* Testimonials Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-gray-50 dark:bg-gray-800 p-8 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                <img
                  className="w-14 h-14 rounded-full object-cover"
                  src={testimonial.image}
                  alt={`${testimonial.name} avatar`}
                  loading="lazy"
                />
                <div className="ml-4">
                  <h5 className="text-xl font-semibold text-gray-900 dark:text-white">
                    {testimonial.name}
                  </h5>
                  <p className="text-gray-600 dark:text-gray-400">{testimonial.role}</p>
                </div>
              </div>
              <div className="flex items-center mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <FaStar key={i} className="text-yellow-400 w-5 h-5" />
                ))}
              </div>
              <p className="text-gray-700 dark:text-gray-300 italic">
                <FaQuoteLeft className="text-blue-600 w-6 h-6 inline-block mr-2" />
                {testimonial.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
