import React, { useState, useEffect } from 'react';
import { FILE_URL } from '../../../redux/env';
import { FaExpandAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from 'react-icons/io5';

const ProjectDescription = ({ fullDescription, images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const openModal = (index) => {
    setActiveImageIndex(index);
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  const closeModal = () => {
    setActiveImageIndex(null);
    setIsModalOpen(false);
    document.body.style.overflow = 'auto'; // Restore background scrolling
  };

  // Navigate to the previous image
  const goToPreviousImage = () => {
    if (activeImageIndex > 0) {
      setActiveImageIndex(activeImageIndex - 1);
    }
  };

  // Navigate to the next image
  const goToNextImage = () => {
    if (activeImageIndex < images.length - 1) {
      setActiveImageIndex(activeImageIndex + 1);
    }
  };

  // Close modal when pressing the Esc key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };
    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isModalOpen]);

  const activeImage = images && images[activeImageIndex];

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-700 mb-6">
            Project Details
          </h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
        <div className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8">
          {images &&
            images.map((getimage, index) => (
              <div
                key={index}
                className="relative group bg-white p-4 rounded-lg shadow-lg overflow-hidden"
              >
                <img
                  src={FILE_URL + getimage.image}
                  alt={getimage.title || 'Project Screenshot'}
                  className="md:w-full md:h-64 object-cover"
                />
                <div
                  onClick={() => openModal(index)}
                  className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg cursor-pointer"
                >
                  <FaExpandAlt className="w-6 h-6 mt-2 text-gray-50" />
                  <p className="text-white font-bold text-lg">{getimage.title}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && activeImage && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-75"
          onClick={closeModal} // Close modal when clicking on the overlay
        >
          <div
            className="relative bg-white rounded-lg max-w-6xl mx-auto my-8 shadow-lg overflow-hidden"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
          >
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <IoClose className="w-8 h-8" />
            </button>

            {/* Left Arrow */}
            {activeImageIndex > 0 && (
              <button
                onClick={goToPreviousImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-3xl p-2 bg-black bg-opacity-50 rounded-full hover:bg-opacity-75 focus:outline-none"
              >
                <FaArrowLeftLong className="w-5 h-5" />
              </button>
            )}

            {/* Image */}
            <img
              src={FILE_URL + activeImage.image}
              alt={activeImage.title || 'Project Image'}
              className="w-full h-auto"
            />

            {/* Right Arrow */}
            {activeImageIndex < images.length - 1 && (
              <button
                onClick={goToNextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-3xl p-2 bg-black bg-opacity-50 rounded-full hover:bg-opacity-75 focus:outline-none"
              >
                <FaArrowRightLong className="w-5 h-5" />
              </button>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default ProjectDescription;
