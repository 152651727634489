import React from 'react';

const ServiceTeamSection = () => {
  return (
    <section className=" py-20">
      <div className="container mx-auto px-6 lg:px-8 text-gray-700">
        <div className="grid lg:grid-cols-12 gap-16 items-center">
          <div className="lg:col-span-5">
            <h2 className="text-3xl md:text-6xl font-semibold mb-8 leading-tight">
              Our team of experts do their best
            </h2>
            <p className="text-xl leading-relaxed mb-8">
              Unlock the potential of generative AI with our experienced team. We drive innovation and excellence in every project we undertake.
            </p>
          </div>
          <div className="lg:col-span-7">
            <div className="bg-white p-10 rounded-2xl shadow text-gray-900">
              <div className="grid gap-10 md:grid-cols-2">
                <div>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>01</span> <p className='my-6'> RESEARCH</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                    Our team conducts thorough research to understand your needs and craft customized AI solutions.
                  </p>
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>02</span> <p className='my-6'> UI DESIGN</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                    We design intuitive and engaging user interfaces that enhance the user experience and drive engagement.
                  </p>
                </div>
                <div className='md:mt-12'>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>03</span> <p className='my-6'> DEVELOP</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                    Our developers build robust and scalable AI solutions tailored to your business needs.
                  </p>
                </div>
                <div className='md:mt-12'>
                  <h3 className="text-2xl font-bold text-blue-700 mb-2">—<span className='text-gray-700 ml-2'>04</span> <p className='my-6'> SUPPORT</p></h3>
                  <p className="text-base text-gray-500 leading-relaxed">
                  We provide ongoing support and optimization to ensure your AI solutions remain effective and up-to-date.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceTeamSection;
