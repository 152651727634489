import {
    PROJECT_LIST_REQUEST,
    PROJECT_LIST_SUCCESS,
    PROJECT_LIST_FAIL,

    PROJECT_DETAILS_REQUEST,    
    PROJECT_DETAILS_SUCCESS,
    PROJECT_DETAILS_FAIL,

    PURCHASED_PROJECT_REQUEST,
    PURCHASED_PROJECT_SUCCESS,
    PURCHASED_PROJECT_FAIL,

    DOWNLOAD_PROJECT_REQUEST,
    DOWNLOAD_PROJECT_SUCCESS,
    DOWNLOAD_PROJECT_FAIL,

    PROJECT_SUPPORT_REQUEST,
    PROJECT_SUPPORT_SUCCESS,
    PROJECT_SUPPORT_FAIL,

    PROJECT_DOCUMENT_REQUEST,
    PROJECT_DOCUMENT_SUCCESS,
    PROJECT_DOCUMENT_FAIL,

    BLOGS_REQUEST,
    BLOGS_SUCCESS,
    BLOGS_FAIL,

    GET_BLOG_REQUEST,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAIL,

    GET_BLOG_BYCATEGORY_REQUEST,
    GET_BLOG_BYCATEGORY_SUCCESS,
    GET_BLOG_BYCATEGORY_FAIL,
} from '../constants/projectConstants';

import { encryptData, decryptData } from '../../utils/encryption';
import { startLoading, stopLoading } from './loadingActions';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_URL } from '../env';
import axiosInstance from '../../Common/axiosInstance';

export const getProjects = () => async (dispatch) => {  
    try {
        dispatch({ type: PROJECT_LIST_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // const cachedData = localStorage.getItem('G23SD#@$SDR');
        // if (cachedData) {
        //     const parsedData = decryptData(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: PROJECT_LIST_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('projects');
        //     }
        // }

        const response = await axios.get(`${API_URL}/projects/`, config);
        const data = response.data;
        localStorage.setItem('G23SD#@$SDR', encryptData({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: PROJECT_LIST_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: PROJECT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getProjectDetails = (id) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: PROJECT_DETAILS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // const cachedData = localStorage.getItem(`F$3SDF&#D7${id}`);
        // if (cachedData) {
        //     const parsedData = decryptData(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: PROJECT_DETAILS_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem(`F$3SDF&#D7${id}`);
        //     }
        // }
        
        const response = await axios.get(`${API_URL}/project/${id}/`, config);
        const data = response.data;

        localStorage.setItem(`F$3SDF&#D7${id}`, encryptData({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: PROJECT_DETAILS_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: PROJECT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}

export const purchasedProject = () => async (dispatch) => {
    try {
        dispatch({ type: PURCHASED_PROJECT_REQUEST });

        const response = await axiosInstance.get('/purchased-projects/');
        const data = response.data;

        dispatch({
            type: PURCHASED_PROJECT_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: PURCHASED_PROJECT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const downloadProject = (contentId) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: DOWNLOAD_PROJECT_REQUEST }); 

        const response = await axiosInstance.post(`/create-download-link/`, {content: contentId});
        const data = response.data;

        dispatch({
            type: DOWNLOAD_PROJECT_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: DOWNLOAD_PROJECT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}

export const getprojectSupports = () => async (dispatch) => {
    try {
        dispatch({ type: PROJECT_SUPPORT_REQUEST });

        const {data} = await axiosInstance.get('/support/');

        dispatch({
            type: PROJECT_SUPPORT_SUCCESS,
            payload: data,
        });

        console.log('data:', data);
        

        return data;
    } catch (error) {
        dispatch({
            type: PROJECT_SUPPORT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getprojectDcument = (permission_id) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: PROJECT_DOCUMENT_REQUEST });

        const {data} = await axiosInstance.get(`/get-ducment/${permission_id}/`);

        dispatch({
            type: PROJECT_DOCUMENT_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: PROJECT_DOCUMENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}

export const getBlogs = () => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: BLOGS_REQUEST });

        const cachedData = localStorage.getItem('ERR#U^&*DSD');
        if (cachedData) {
            const parsedData = decryptData(cachedData);
            const expirationTime = 2 * 60 * 60 * 1000; // 2 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: PROJECT_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('ERR#U^&*DSD');
            }
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const {data} = await axios.get(`${API_URL}/get-blogs/`, config);
        localStorage.setItem('ERR#U^&*DSD', encryptData({ data, timestamp: new Date().getTime() }));
        
        dispatch({
            type: BLOGS_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: BLOGS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}

export const getBlog = (slug) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: GET_BLOG_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const {data} = await axios.get(`${API_URL}/get-blog/${slug}/`, config);

        dispatch({
            type: GET_BLOG_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: GET_BLOG_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}   

export const getBlogByCategory = (category) => async (dispatch) => {
    try {
        dispatch(startLoading());
        dispatch({ type: GET_BLOG_BYCATEGORY_REQUEST });

        const cachedData = localStorage.getItem('GGD#^&*DSD');
        if (cachedData) {
            const parsedData = decryptData(cachedData);
            const expirationTime = 2 * 60 * 60 * 1000; // 2 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: PROJECT_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('GGD#^&*DSD');
            }
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const {data} = await axios.get(`${API_URL}/get-blog/category/${category}/`, config);
        localStorage.setItem('GGD#^&*DSD', encryptData({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: GET_BLOG_BYCATEGORY_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: GET_BLOG_BYCATEGORY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    } finally {
        dispatch(stopLoading());
    }
}