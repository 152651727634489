import React from 'react';

const ContactHero = () => {
  return (
    <section className="relative py-16 lg:py-28 text-center bg-gradient-to-r from-blue-50 via-white to-blue-50 overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0">
          <div className="h-full w-full bg-gradient-to-b from-blue-50 via-white to-blue-50 opacity-90"></div>
        </div>  

      <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
      <h1 
          data-aos="fade-up"
          className="text-3xl md:text-6xl max-w-4xl mx-auto font-extrabold text-gray-900 mb-6"
        >
          Get in Touch with <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">GenAI Box</span>
        </h1>
        <p 
          data-aos="fade-up"
          data-aos-delay="200"
          className="text-md md:text-xl font-semibold text-gray-600 max-w-2xl mx-auto mb-8">
          Need assistance with our AI solutions? Our dedicated team is ready to answer your questions and provide the support you need. Let’s discuss how <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">GenAI Box</span> can help transform your business operations.
        </p>

      </div>
    </section>
  );
};

export default ContactHero;
